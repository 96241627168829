import React, { useState, useEffect, useContext, createContext } from 'react';
import {
  PublicClientApplication,
  AccountInfo,
  AuthorizationUrlRequest,
  AuthError,
  AuthenticationResult,
  SilentRequest,
  BrowserAuthError
} from '@azure/msal-browser';

import SignInTypes from 'constants/signInTypes';
import { isEdge, isIE } from 'constants/browser';

import { getUrlPathWithoutOrigin } from 'utils/url/getUrlPathWithoutOrigin';
import { BANK_REFERENCES } from 'constants/routes';
import MSAL_CONFIG, {
  PROFILE_REDIRECT,
  getMsalForgotPasswordConfig,
  FORGOT_PASSWORD_CODE
} from './msalConfig';

interface IMSALProvider {
  children: JSX.Element;
}

interface MSALContextDefaultValue {
  logout: () => void;
  fetchAccessToken: () => Promise<void>;
  account: AccountInfo | undefined;
  accessToken: string | null;
  loading: boolean;
  isAuthenticated: boolean;
}

export const MSALContext = createContext<MSALContextDefaultValue>(
  {} as MSALContextDefaultValue
);

export const MSALProvider = ({ children }: IMSALProvider) => {
  const msal = new PublicClientApplication(MSAL_CONFIG);
  const [account, setAccount] = useState<AccountInfo>();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  const getAccount = (): AccountInfo | null => {
    const currentAccounts = msal.getAllAccounts();

    if (currentAccounts.length) {
      const [firstAccount] = currentAccounts;

      return firstAccount;
    }

    console.warn('No accounts detected');
    return null;
  };

  const login = async (
    loginRequest: AuthorizationUrlRequest,
    method: string
  ): Promise<void> => {
    const signInType = isIE || isEdge ? SignInTypes.loginRedirect : method;

    if (signInType === SignInTypes.loginPopup) {
      await msal.loginPopup(loginRequest);
      const acc = getAccount();
      if (acc) setIsAuthenticated(true);
    } else if (signInType === SignInTypes.loginRedirect) {
      try {
        await msal.loginRedirect(loginRequest);
      } catch (e) {
        if (e instanceof BrowserAuthError) {
          // Do nothing, emits "interaction in progress error"
        }
      }
    }
  };

  const logout = () => {
    msal.logout({ account });
    setAccount(undefined);
  };

  const fetchAccessToken = async () => {
    const acc = getAccount();
    if (!acc) return;

    const loginRequest: SilentRequest = {
      scopes: PROFILE_REDIRECT.scopes,
      account: acc,
      forceRefresh: false
    };

    try {
      let res: AuthenticationResult = await msal.acquireTokenSilent(
        loginRequest
      );

      if (!res.accessToken) {
        res = await msal.ssoSilent({
          ...loginRequest,
          loginHint: acc.username
        });
      }
      setAccessToken(res.accessToken);
    } catch (error) {
      console.error('silent token acquisition failed', error);
      logout();
    }
  };

  const handleAuth = async () => {
    try {
      const response = await msal.handleRedirectPromise();

      if (response) {
        getAccount();
        setLoading(false);
        setIsAuthenticated(true);

        if (response.accessToken) {
          setAccessToken(response.accessToken);
        }
      }
    } catch (error) {
      if (error instanceof AuthError) {
        // Redirects to forgot password page.

        // Possibly does not work to click the back button
        if (error.message.indexOf(FORGOT_PASSWORD_CODE) > -1) {
          const forgotPasswordMsal = new PublicClientApplication(
            getMsalForgotPasswordConfig()
          );

          forgotPasswordMsal.loginRedirect(PROFILE_REDIRECT);
        }
      }
    }
  };


  useEffect(() => {
    const init = async () => {
      await handleAuth();

      const acc = getAccount();
      if (acc) {
        if (!accessToken) {
          await fetchAccessToken();
        }
        setLoading(false);
        setIsAuthenticated(true);
        setAccount(acc);
      } else {
        login(PROFILE_REDIRECT, SignInTypes.loginRedirect);
      }
    };

    init();
    // eslint-disable-next-line
  }, []);

  return (
    <MSALContext.Provider
      value={{
        logout,
        fetchAccessToken,
        account,
        accessToken,
        loading,
        isAuthenticated
      }}
    >
      {children}
    </MSALContext.Provider>
  );
};

export const useMSAL = () => useContext(MSALContext);
