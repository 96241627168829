/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import { Customer, CustomerService, EnumCustomerCustomerType, User } from 'api';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useMSAL } from 'auth/msalProvider';

function useCurrentCustomer() {
  const { isAuthenticated, accessToken } = useMSAL();
  const [{ userCookie: customerId }] = useCookies(['X-FJELLINJEN']);

  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

  const isBusinessCustomer =
    customer?.customerType === EnumCustomerCustomerType.Company;

  const fetchCustomer = useCallback(async () => {
    if (!customerId) return;
    try {
      const res: Customer = await CustomerService.getCustomer({ customerId });

      if (res) setCustomer(res);
    } catch (e) {
      if (!axios.isCancel(e)) console.error(e);
    }
  }, [customerId]);

  useEffect(() => {
    if (isAuthenticated && accessToken && !customer) {
      fetchCustomer();
    }
    setIsCustomerLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, accessToken, fetchCustomer]);

  const currentShippingAddress = customer?.shippingAddress
    ? customer?.shippingAddress
    : customer?.customerAddress;

  return {
    customer,
    loggedInUser,
    isCustomerLoading,
    isBusinessCustomer,
    currentShippingAddress,
    setCustomer,
    setLoggedInUser
  };
}

export default createContainer(useCurrentCustomer);
