{
  "locale": "nb-NO",
  "integrations": {
    "gateway_host": "https://func-fjeltsp-tsp-self-gateway-prod.azurewebsites.net"
  },
  "analytics": {
    "enabled": true,
    "trackingId": "G-3DD3KCJCS6"
  },
  "authentication": {
    "clientId": "0ca86f2c-959e-429a-acce-bc49e95b7c2d",
    "tenant": "fjeltspb2c.onmicrosoft.com",
    "B2CHostname": "fjeltspb2c.b2clogin.com",
    "policyName": "B2C_1A_AUTOSYNC_2S_SIGN_IN_BANKID",
    "policyForgotPassword": "B2C_1_2S_RESET",
    "scopes": [
      "openid",
      "profile",
      "https://auth.fremtindservice.no/2S-Gateway/RW"
    ]
  },
  "applicationInsight": {
    "instrumentationKey": "591ffb8b-eb59-4f50-8a13-74093ab8501b"
  }
}