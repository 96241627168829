import { createContainer } from 'unstated-next';
import { useState, useEffect, useCallback } from 'react';
import {
  AgreementsService,
  AgreementWithOBEsAndVehicles,
  CustomerAgreementsWithOBEsAndVehiclesSearchResult
} from 'api';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useMSAL } from 'auth/msalProvider';

export interface IUpdateName {
  name: string;
  id: string;
}

function useAgreementResults() {
  const [{ userCookie }] = useCookies(['X-FJELLINJEN']);
  const { isAuthenticated, accessToken: token } = useMSAL();

  const [agreements, setAgreements] = useState<
    AgreementWithOBEsAndVehicles[] | null
  >(null);

  const fetchItems = useCallback(async () => {
    try {
      const res: CustomerAgreementsWithOBEsAndVehiclesSearchResult = await AgreementsService.getAgreementsWithObEsAndVehiclesForCustomer(
        {
          customerId: userCookie,
          body: {}
        }
      );

      if (res?.results?.length) setAgreements(res.results);
    } catch (e) {
      if (!axios.isCancel(e)) console.error(e);
    }
  }, [userCookie]);

  useEffect(() => {
    if (isAuthenticated && token && userCookie) fetchItems();
  }, [fetchItems, isAuthenticated, token, userCookie]);

  const findAgreement = (agreementNumber: string | number | undefined) => {
    if (agreementNumber) {
      const chosenAgreement = agreements?.find(
        (agreement) =>
          agreement.agreementNumber?.toString() === agreementNumber.toString()
      );
      return chosenAgreement;
    }

    return null;
  };

  const updateName = ({ name = '', id = '' }: IUpdateName) => {
    const updated =
      agreements?.map((agreement) => {
        if (agreement.id === id) {
          const updatedAgreement = { ...agreement };
          updatedAgreement.name = name;
          return updatedAgreement;
        }

        return agreement;
      }) ?? null;

    setAgreements(updated);
  };

  return {
    agreements,
    updateName,
    setAgreements,
    findAgreement
  };
}

export default createContainer(useAgreementResults);
