import { Box } from '@material-ui/core';
import React from 'react';
import AutoSyncLogoAnimated from '../AutoSyncLogoAnimated/AutoSyncLogoAnimated';
import useStyles from './LoadingPageStyles';

export default function LoadingPage() {
  const { root } = useStyles();
  return (
    <Box className={root}>
      <AutoSyncLogoAnimated />
    </Box>
  );
}
