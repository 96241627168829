import React, { useState, useEffect } from 'react';
import {
  AccountCircle,
  Person,
  SupervisorAccount,
  Language,
  ExitToApp,
  HelpOutline
} from '@material-ui/icons';

import {
  Menu,
  IconButton,
  MenuItem,
  Collapse,
  List,
  ClickAwayListener
} from '@material-ui/core';

import * as moment from 'moment';
import { CONTACT_US } from 'constants/urls';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { CUSTOMERS, PROFILE } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import Lang from 'constants/language';
import { useMSAL } from 'auth/msalProvider';

import useStyles from '../NavigationStyles';

const NavigationProfileMenu = () => {
  const onCustomersPage = useRouteMatch(CUSTOMERS)?.isExact;
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<null | HTMLElement>(null);
  const {
    language,
    icon,
    profileMenu,
    menu,
    contactUs,
    navbarMenuIcon
  } = useStyles();
  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const open = Boolean(isOpen);

  const { logout } = useMSAL();

  const handleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(event.currentTarget);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event?: any) => {
    // Prevent from closing menu when pressing the language menu button
    if (event?.target?.getAttribute('aria-label') === 'language menu button') return;
    setIsOpen(null);
  };

  const changeLanguage = (lng: string) => () => {
    setIsOpen(null);
    setIsLanguageMenuOpen(false);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const lang =
      i18n.languages[0] === Lang.norwegian
        ? Lang.norwegianBokmal
        : Lang.english;

    moment.locale(lang);
  }, [i18n.languages]);

  return (
    <ClickAwayListener disableReactTree onClickAway={handleClose}>
      <div className={menu}>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle className={navbarMenuIcon} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={isOpen}
          keepMounted
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={handleClose}
          className={profileMenu}
        >
          {!onCustomersPage && (
            <MenuItem
              onClick={() => {
                push(PROFILE);
                handleClose();
              }}
              aria-label="profile menu button"
            >
              <Person className={icon} />
              {t('profile_menu.profile')}
            </MenuItem>
          )}

          {!onCustomersPage && (
            <MenuItem
              onClick={() => {
                push(CUSTOMERS);
                handleClose();
              }}
              aria-label="customers menu button"
            >
              <SupervisorAccount className={icon} />
              {t('profile_menu.customers')}
            </MenuItem>
          )}

          <MenuItem
            onClick={handleLanguageMenu}
            className={language}
            aria-label="language menu button"
          >
            <Language className={icon} />
            {t('profile_menu.language')}
          </MenuItem>

          <Collapse in={isLanguageMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <MenuItem
                onClick={changeLanguage(Lang.english)}
                aria-label="english language button"
              >
                {t('profile_menu.languages.english')}
              </MenuItem>
              <MenuItem
                onClick={changeLanguage(Lang.norwegian)}
                aria-label="norwegian language button"
              >
                {t('profile_menu.languages.norwegian')}
              </MenuItem>
            </List>
          </Collapse>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            aria-label="contact us"
          >
            <Link
              to={{
                pathname: CONTACT_US
              }}
              target="_blank"
              rel="noopener noreferrer"
              className={contactUs}
            >
              <HelpOutline className={icon} />
              {t('profile_menu.contact_us')}
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              logout();
              handleClose();
            }}
            aria-label="logout button"
          >
            <ExitToApp className={icon} />
            {t('profile_menu.logout')}
          </MenuItem>
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default NavigationProfileMenu;
