import { lazy } from 'react';
import {
  CUSTOMERS,
  CUSTOMERS_RECOVER,
  AGREEMENTS,
  BANK_REFERENCE,
  BANK_REFERENCES,
  CASES,
  INVOICE,
  INVOICES,
  PASSINGS,
  TAG_DETAILS,
  TRANSFER_TAG_BY_ID,
  TRANSFER_TAG_BULK,
  TRANSFER_TAG_BULK_DOCS,
  ORDER_EMPTY_TAG,
  ORDER_TAG,
  REMOVE_VEHICLE_BY_ID,
  REGISTER_BLANK_TAG,
  LEGACY_INVOICES,
  PROFILE,
  EDIT_VEHICLE,
  HOME
} from 'constants/routes';

import TransferTagStore from 'store/transferTag/useTransferTag';
import BulkTransferTagStore from 'store/transferTag/useBulkTransferTag';
import SearchVehicleStore from 'store/vehicle/useSearchVehicle';
import RegisterBlankTagStore from 'store/registerBlankObe/useRegisterBlankTag';
import UnregisterVehicleStore from 'store/unregisterVehicle/useUnregisterVehicle';
import OrderTagStore from 'store/orderTag/useOrderTag';
import { PRIVILEGE_GRANT_TOKEN } from '../constants/routes';

export default [
  {
    path: CUSTOMERS,
    component: lazy(() => import('modules/Customers/Customers')),
    name: 'Customers',
    exact: true
  },
  {
    path: CUSTOMERS_RECOVER,
    component: lazy(
      () =>
        import(
          'modules/Customers/CustomersRecoverExisting/CustomersRecoverExisting'
        )
    ),
    name: 'CustomersRecoverExisting',
    exact: true
  },
  {
    path: PROFILE,
    component: lazy(() => import('modules/Profile/Profile')),
    name: 'Profile',
    exact: true
  },
  {
    path: AGREEMENTS,
    component: lazy(() => import('modules/Agreements/Agreements')),
    name: 'Agreements',
    exact: true
  },
  {
    path: BANK_REFERENCES,
    component: lazy(() => import('modules/BankReference/BankReference')),
    name: 'BankReferences',
    exact: true
  },
  {
    path: BANK_REFERENCE(':referenceId', ':redirectUrl', ':shortname'),
    component: lazy(() => import('modules/BankReference/BankReference')),
    name: 'BankReference',
    exact: true
  },
  {
    path: PASSINGS,
    component: lazy(() => import('modules/Passings/Passings')),
    name: 'Passings',
    exact: true
  },
  {
    path: CASES,
    component: lazy(() => import('modules/Cases/Cases')),
    name: 'Cases',
    exact: true
  },
  {
    path: HOME,
    component: lazy(() => import('modules/Home/Home')),
    name: 'Home',
    exact: true
  },
  {
    path: TRANSFER_TAG_BULK,
    provider: [BulkTransferTagStore.Provider],
    component: lazy(() => import('modules/TransferTag/Bulk/BulkTransferTag')),
    name: 'Transfer Tag Bulk',
    exact: true
  },
  {
    path: TRANSFER_TAG_BULK_DOCS,
    component: lazy(
      () => import('modules/TransferTag/Bulk/BulkTransferTagDocs')
    ),
    name: 'Transfer Tag Bulk Docs',
    exact: true
  },
  {
    path: TRANSFER_TAG_BY_ID(':tagId?'),
    provider: [
      TransferTagStore.Provider,
      SearchVehicleStore.Provider,
      OrderTagStore.Provider
    ],
    component: lazy(() => import('modules/TransferTag/TransferTag')),
    name: 'Transfer Tag',
    exact: true
  },
  {
    path: TAG_DETAILS(':obeNumber'),
    component: lazy(
      () =>
        import('modules/TagDetails/DownloadForeignVehicleDocumentationButton')
    ),
    name: 'Vehicle information',
    exact: true
  },
  {
    path: EDIT_VEHICLE(':obeNumber'),
    provider: [SearchVehicleStore.Provider],
    component: lazy(
      () => import('components/SearchVehicle/EditVehicleFormPage')
    ),
    name: 'Edit vehicle',
    exact: true
  },
  {
    path: REGISTER_BLANK_TAG,
    provider: [
      RegisterBlankTagStore.Provider,
      SearchVehicleStore.Provider,
      OrderTagStore.Provider
    ],
    component: lazy(() => import('modules/RegisterBlankTag/RegisterBlankTag')),
    name: 'Register blank tag(s)',
    exact: true
  },
  {
    path: LEGACY_INVOICES,
    component: lazy(() => import('modules/Invoices/LegacyInvoices/LegacyList')),
    name: 'Legacy invoices list',
    exact: true
  },
  {
    path: INVOICES,
    component: lazy(() => import('modules/Invoices/Invoices')),
    name: 'Invoices',
    exact: true
  },
  {
    path: INVOICE(':invoiceNumber'),
    component: lazy(
      () => import('modules/Invoices/InvoiceDetails/InvoiceDetails')
    ),
    name: 'Invoice',
    exact: true
  },
  {
    path: LEGACY_INVOICES,
    component: lazy(() => import('modules/Invoices/LegacyInvoices/LegacyList')),
    name: 'Legacy invoices list',
    exact: true
  },
  {
    path: ORDER_EMPTY_TAG,
    component: lazy(() => import('modules/OrderEmptyTags/OrderEmptyTags')),
    name: 'Empty tags',
    exact: true
  },
  {
    path: ORDER_TAG,
    provider: [OrderTagStore.Provider, SearchVehicleStore.Provider],
    component: lazy(() => import('modules/OrderTag/OrderTag')),
    name: 'Tag',
    exact: true
  },
  {
    path: REMOVE_VEHICLE_BY_ID(':tagId?'),
    provider: UnregisterVehicleStore.Provider,
    component: lazy(
      () => import('modules/UnregisterVehicle/UnregisterVehicle')
    ),
    name: 'Transfer Tag',
    exact: true
  },
  {
    path: PRIVILEGE_GRANT_TOKEN(':token'),
    component: lazy(() => import('modules/Customers/Customers')),
    name: 'Activate privilege',
    exact: true
  }
];
