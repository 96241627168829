import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BORDER_GRAY } from 'constants/colors';

export default makeStyles((theme: Theme) => {
  return createStyles({
    divider: {
      margin: theme.spacing(5, 0),
      backgroundColor: BORDER_GRAY
    }
  });
});
