import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    video: { 
        width: '80%',
        height: '80%',
    }
  })
);
