import {
  createStyles,
  makeStyles,
  Theme,
  alpha
} from '@material-ui/core/styles';
import { WHITE, AUTOSYNC_BLACK } from 'constants/colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0
    },

    menu: {
      zIndex: 9999
    },

    title: {
      flexGrow: 1,
      textAlign: 'left',
      marginLeft: theme.spacing(2)
    },

    nameTitle: {
      [theme.breakpoints.down('xs')]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    },

    logo: {
      marginRight: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      borderRight: `1px solid ${WHITE}`,
      width: '322.03px',
      height: 'Fill(32px)',
      paddingTop: '10px',
      paddingLeft: '10px'
    },

    list: {
      width: 250
    },

    listItem: {
      boxShadow: '0 1px black'
    },

    icon: {
      marginRight: theme.spacing(1.5)
    },

    language: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      borderTop: `1px solid ${theme.palette.primary.main}`
    },

    text: {
      color: AUTOSYNC_BLACK,
      marginLeft: theme.spacing(1)
    },

    button: {
      color: AUTOSYNC_BLACK,
      boxSizing: 'border-box',

      '&.active': {
        textDecoration: 'underline'
      },

      '&:first-child': {
        marginLeft: theme.spacing(1)
      }
    },

    box: {
      backgroundColor: theme.palette.primary.main
    },

    navigationBox: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2, 3, 2, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0px 2px 30px 5px rgba(227,227,227,0.88)'
    },

    profileMenu: {
      '& div': {
        width: 200
      }
    },

    navLinks: {
      height: theme.spacing(4),
      color: AUTOSYNC_BLACK
    },

    drawerLogo: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
      marginRight: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      height: '60px',
      width: '100%',
      boxShadow: '0 0 5px black'
    },

    drawerDivider: {
      backgroundColor: alpha(WHITE, 0.35)
    },

    navigationProfileBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexGrow: 1,
      textAlign: 'right',
      maxWidth: '90%'
    },

    navigationProfile: {
      display: 'flex',
      flexDirection: 'column'
    },

    profileInfoStyles: {
      lineHeight: 1
    },

    customersBanner: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },

    logoBanner: {
      display: 'flex',
      marginRight: theme.spacing(2),
      cursor: 'pointer'
    },

    contactUs: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: AUTOSYNC_BLACK
    },

    navbarMenuIcon: {
      width: '50px',
      height: '50px',
      marginBottom: '5px'
    }
  })
);
