import React from 'react';
import IIcon from 'common/interfaces/IIcon';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore next-line
import Logo from 'url:../../../assets/Logo/AutoSync-Logo-RGB-Positiv.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore next-line
import LogoInverted from 'url:../../../assets/Logo/AutoSync-Logo-RGB-Negativ.svg';

export default function AutosyncLogo({
  height = '35',
  width = '240',
  invertColors,
  style
}: IIcon) {
  return (
    <img
      width={width}
      height={height}
      src={invertColors ? LogoInverted : Logo}
      style={style}
    />
  );
}
