import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MSALProvider } from 'auth/msalProvider';
import SnackProvider from 'components/Snackbar/SnackProvider';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { reactPlugin } from 'utils/appInsights/appInsights';
import '../assets/sass/App.scss';
import i18n from './i18n/config/i18n';
import Routes from './routes';
import lightModeTheme from './style/lightModeTheme';
import darkModeTheme from './style/darkModeTheme';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <CookiesProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider
            theme={prefersDarkMode ? darkModeTheme : lightModeTheme}
          >
            <SnackProvider>
              <MSALProvider>
                <>
                  <CssBaseline />
                  <Routes />
                </>
              </MSALProvider>
            </SnackProvider>
          </ThemeProvider>
        </I18nextProvider>
      </CookiesProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
