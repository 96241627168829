/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement } from 'react';

// Combines multiple providers from unstated-next
// Use <Compose providers={[Provider, Provider]}>{children}</Compose>
const Compose = ({ providers = [], children }: any) => {
  if (providers.length < 0) {
    return children;
  }

  return providers.reduce(
    (acc: any, cur: any) => createElement(cur, [], acc),
    children
  );
};

export default Compose;
