import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './TextStyles';
import IText from './IText';

export default function Info({ children, align, variant, className }: IText) {
  const { info } = useStyles();

  return (
    <Typography
      aria-label="help-text"
      align={align}
      className={`${info} ${className}`}
      variant={variant}
    >
      {children}
    </Typography>
  );
}

Info.defaultProps = {
  align: 'left',
  variant: 'body2'
};
