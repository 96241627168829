import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AUTOSYNC_BLACK, WHITE } from 'constants/colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      width: 'auto',
      margin: theme.spacing(3, 'auto')
    },
    autocomplete: {
      margin: '0 auto'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      // 50% of the size of the spinner (16px)
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-1)
    },
    errorBox: {
      margin: theme.spacing(0.5)
    },
    white: {
      color: WHITE
    },
    black: {
      color: AUTOSYNC_BLACK
    }
  })
);
