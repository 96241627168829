import React from 'react';
import { AUTOSYNC_BLACK } from 'constants/colors';
import IIcon from 'common/interfaces/IIcon';

export default function ElectronicWasteInfoBackground({ fill }: IIcon) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.19922 16.0602L3.87922 12.0002L2.19922 7.9402L6.25922 6.2602L7.93922 2.2002L11.9992 3.8802L16.0592 2.2002L17.7392 6.2602L21.7992 7.9402L20.1192 12.0002L21.7992 16.0602L17.7392 17.7402L16.0592 21.8002L11.9992 20.1202L7.93922 21.8002L6.25922 17.7402L2.19922 16.0602ZM4.80922 9.0002L6.04922 12.0002L4.80922 15.0002L7.78922 16.2102L8.99922 19.1902L11.9992 17.9502L14.9992 19.1902L16.2092 16.2102L19.1892 15.0002L17.9492 12.0002L19.1892 9.0002L16.2092 7.7902L14.9992 4.8102L11.9992 6.0502L8.99922 4.8102L7.78922 7.7902L4.80922 9.0002ZM10.9992 15.0002H12.9992V17.0002H10.9992V15.0002ZM10.9992 7.0002H12.9992V13.0002H10.9992V7.0002Z"
        fill={fill}
      />
    </svg>
  );
}

ElectronicWasteInfoBackground.defaultProps = {
  fill: AUTOSYNC_BLACK
};
