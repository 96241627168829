import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { applicationInsight } from '../../../env-config.json';
import history from '../history/history';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${applicationInsight.instrumentationKey}`,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
      withAITracking: true,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true
    }
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export { reactPlugin, appInsights };
