import { createContainer } from 'unstated-next';
import { useState } from 'react';
import InvoiceType from 'constants/invoiceTypes';

function useInvoiceType() {
  const [invoiceType, setInvoiceType] = useState<InvoiceType>(
    InvoiceType.unpaid
  );

  return {
    invoiceType,
    setInvoiceType
  };
}

export default createContainer(useInvoiceType);
