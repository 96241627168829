import { createContainer } from 'unstated-next';
import { useState } from 'react';
import IVehicle from 'common/interfaces/IVehicle';
import useFileAttachment from 'hooks/files/useFileAttachment';
import { CreateVehicleDocumentationRequest } from 'api';
import { vehicleDocumentationFileSizeLimit } from 'constants/fileSizeLimits';

const useSearchVehicle = () => {
  const [searchVehicle, setSearchVehicle] = useState<IVehicle | null>(null);
  const vehicleDocumentationAttachment = useFileAttachment<
    CreateVehicleDocumentationRequest
  >(vehicleDocumentationFileSizeLimit);

  return {
    searchVehicle,
    setSearchVehicle,
    vehicleDocumentationAttachment
  };
};

export default createContainer(useSearchVehicle);
