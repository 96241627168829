import '@fremtind/jkl-footer/footer.min.css';
import { Box } from '@material-ui/core';
import ExternalNavLinkButton from 'components/Button/ExternalNavLinkButton';
import NavLinkButton from 'components/Button/NavLinkButton';
import AutosyncLogo from 'components/Icons/AutosyncLogo';
import { AGREEMENTS, HOME, INVOICES, PASSINGS } from 'constants/routes';
import { EXTERNAL_HELP_PAGE } from 'constants/urls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './FooterStyles';

interface IFooterProps {
  hideLinks?: boolean;
}

function Footer({ hideLinks = false }: IFooterProps) {
  const { t } = useTranslation();
  const { footerBox, navLinks, button, actions, icon } = useStyles();

  return (
    <>
      <footer className={footerBox}>
        <Box className={actions}>
          <Box className={icon}>
            <AutosyncLogo height="55px" width="289px" invertColors />
          </Box>
          {!hideLinks && (
            <Box className={navLinks}>
              <NavLinkButton
                path={HOME}
                className={button}
                label={t('titles.home')}
                ariaLabel="link to home"
              />

              <NavLinkButton
                path={AGREEMENTS}
                className={button}
                label={t('titles.agreements')}
                ariaLabel="link to agreements"
              />

              <NavLinkButton
                path={PASSINGS}
                className={button}
                label={t('titles.passings')}
                ariaLabel="navlink-passings"
              />

              <NavLinkButton
                path={INVOICES}
                className={button}
                label={t('titles.invoices')}
                ariaLabel="navlink-invoices"
              />

              <ExternalNavLinkButton
                path={EXTERNAL_HELP_PAGE}
                className={button}
                label={t('titles.help')}
                ariaLabel="link to help page"
              />
            </Box>
          )}
        </Box>
      </footer>
    </>
  );
}

export default Footer;
