import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { WHITE, AUTOSYNC_DARK_PURPLE } from 'constants/colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    linkColor: {
      '& div': {
        '& ul': {
          '& li': {
            '& a': {
              color: WHITE
            }
          }
        }
      }
    },
    footerBox: {
      color: theme.palette.primary.main,
      backgroundColor: AUTOSYNC_DARK_PURPLE,
      padding: theme.spacing(4, 1, 5, 4),
      margin: 0,
      justifyContent: 'center'
    },
    navLinks: {
      height: theme.spacing(4),
      color: theme.palette.primary.main,
      display: 'flex',
      '@media only screen and (min-width: 0px) and (max-width: 820px)': {
        flexDirection: 'column',
        justifyContent: 'start',
        paddingBottom: theme.spacing(10),
        paddingTop: theme.spacing(2)
      },
      '@media only screen and (min-width: 820px)': {
        flexDirection: 'row',
        justifyContent: 'right'
      }
    },
    button: {
      color: theme.palette.primary.main,
      boxSizing: 'border-box',

      '&.active': {
        textDecoration: 'underline'
      },
      justifySelf: 'left',
      justifyContent: 'left'
    },
    actions: {
      padding: 0,
      display: 'flex',
      '@media only screen and (min-width: 0px) and (max-width: 820px)': {
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: theme.spacing(16)
      },
      '@media only screen and (min-width: 820px)': {
        flexDirection: 'row',
        justifyContent: 'space-between'
      }
    },
    icon: {
      justifyContent: 'center'
    }
  })
);
