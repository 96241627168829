import { Box, IconButton, SwipeableDrawer, Toolbar, Typography } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import Divider from 'components/Divider/Divider';
import Icon from 'components/Icons';
import { Title } from 'components/Text';
import { AUTOSYNC_LOGO } from 'constants/icons';
import { CUSTOMERS, CUSTOMERS_RECOVER } from 'constants/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import CurrentCustomer from 'store/customer/useCurrentCustomer';
import useStyles from '../NavigationStyles';
import NavigationProfileMenu from '../ProfileMenu/NavigationProfileMenu';
import NavigationDrawerList from './NavigationDrawerList';

interface NavigationDrawerInterface {
  isNavLinksEnabled: boolean;
}

export default function NavigationDrawer({
  isNavLinksEnabled = true
}: NavigationDrawerInterface) {
  const onCustomersPage = useRouteMatch(CUSTOMERS)?.isExact;
  const onCustomersRecoverPage = useRouteMatch(CUSTOMERS_RECOVER)?.isExact;

  const {
    drawerLogo,
    navigationProfile,
    navigationProfileBox,
    drawerDivider,
    nameTitle,
    customersBanner
  } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { customer, isBusinessCustomer } = CurrentCustomer.useContainer();
  const { t } = useTranslation();

  const name = isBusinessCustomer
    ? customer?.organizationName || ''
    : `${customer?.personFirstName || ''} ${customer?.personLastName || ''}`;

  const customerNumber = customer?.customerNumber;

  const toggleDrawer = () => setIsOpen(!isOpen);

  if ((onCustomersPage || onCustomersRecoverPage) && isNavLinksEnabled) {
    return (
      <Toolbar>
        <Box width="100%" mt={1}>
          <div className={customersBanner}>
            <div className="customers-logo">
              <Icon name={AUTOSYNC_LOGO} />
            </div>
            <NavigationProfileMenu />
          </div>
        </Box>
      </Toolbar>
    );
  }

  return (
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
      >
        <Menu />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <Box className={drawerLogo}>
          <Icon name={AUTOSYNC_LOGO} />
        </Box>
        <Divider className={drawerDivider} hasDefaultSpacing={false} />
        <NavigationDrawerList />
      </SwipeableDrawer>

      <Box className={navigationProfileBox}>
        {!onCustomersPage && (
          <Box className={navigationProfile}>
            <Title className={nameTitle} align="right">
              {name || ''}
            </Title>
            <Typography variant='subtitle2'>
              {(customerNumber &&
                `${t('customers.customer_number')}: ${customerNumber}`) ||
                ''}
            </Typography>
          </Box>
        )}
        <NavigationProfileMenu />
      </Box>
    </Toolbar>
  );
}
