import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AUTOSYNC_BLACK,
  AUTOSYNC_DARK_PURPLE,
  WHITE
} from 'constants/colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      paddingBottom: '10px'
    },
    link: {
      display: 'flex',
      textAlign: 'left',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bolder'
    },
    icon: {
      marginRight: theme.spacing(2),
      color: AUTOSYNC_BLACK
    },
    primaryButton: {
      backgroundColor: `${AUTOSYNC_DARK_PURPLE}`,
      '--button-primary-background-color': AUTOSYNC_DARK_PURPLE,
      '--button-primary-text-color': `${WHITE}`,
      '--jkl-button-primary-text-color': WHITE,
      '--jkl-button-border-color': AUTOSYNC_BLACK,
      border: '1px solid black',
      '& :first-child': {
        display: 'flex',
        alignItems: 'center'
      },
      '&:hover': {
        backgroundColor: `${AUTOSYNC_DARK_PURPLE}`
      }
    },
    secondaryButton: {
      position: 'relative',
      color: AUTOSYNC_BLACK,
      borderColor: `${AUTOSYNC_BLACK} !important`,
      '& div': {
        '& div': {
          color: 'black'
        }
      },

      '& :first-child': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    download: {
      display: 'flex',
      alignItems: 'center',
      color: AUTOSYNC_BLACK
    },
    backButtonWrapper: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center'
    },
    backButtonTitle: {
      paddingLeft: '20px',
      paddingBottom: '10px'
    },
    spacingRight: {
      marginRight: theme.spacing(2)
    },
    stepperRoot: {
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    navLinkLabel: {
      color: AUTOSYNC_BLACK,
      fontSize: '1.3rem'
    },
    fileInput: {
      display: 'none'
    },
    fileButton: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  })
);
