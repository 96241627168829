import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },

    footer: {
      marginTop: 'auto'
    },

    flexWrapper: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column'
    },

    flexGrow: {
      flex: '1 1 auto',
      flexGrow: 1
    }
  })
);
