import { AppBar, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import NavigationDrawer from './Drawer/NavigationDrawer';
import NavigationNavbar from './NavBar/NavigationNavbar';
import './Navigation.scss';
import useStyles from './NavigationStyles';

interface NavigationInterface {
  isNavLinksEnabled?: boolean;
}
export default function Navigation({
  isNavLinksEnabled = true
}: NavigationInterface) {
  const { root } = useStyles();
  const theme = useTheme();
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div className={root}>
      <AppBar position="static">
        {isLargeDevice ? (
          <NavigationNavbar isNavLinksEnabled={isNavLinksEnabled} />
        ) : (
          <NavigationDrawer isNavLinksEnabled={isNavLinksEnabled} />
        )}
      </AppBar>
    </div>
  );
}
