import { Box, CircularProgress, Icon } from '@material-ui/core';
import React from 'react';
import useStyles from './SpinnerStyles';

interface ISpinner {
  isButtonProgress?: boolean;
  isWhiteSpinner?: boolean;
  isAutocompleteSpinner?: boolean;
  isErrorBoxSpinner?: boolean;
  size?: number;
}

export default function Spinner({
  isButtonProgress,
  isWhiteSpinner,
  isAutocompleteSpinner,
  isErrorBoxSpinner,
  size
}: ISpinner) {
  const { root, autocomplete, buttonProgress, errorBox, white, black } =
    useStyles();

  if (isButtonProgress) {
    return (
      <Box>
        <CircularProgress
          size={size ?? 16}
          className={`${buttonProgress} ${isWhiteSpinner ? white : ''}`}
          aria-busy="true"
          aria-describedby="loading"
        />
      </Box>
    );
  }

  if (isAutocompleteSpinner) {
    return (
      <Box>
        <CircularProgress
          size={size ?? 16}
          className={`${autocomplete} ${isWhiteSpinner ? white : ''}`}
          aria-busy="true"
          aria-describedby="loading"
        />
      </Box>
    );
  }

  if (isErrorBoxSpinner) {
    return (
      <Icon>
        <CircularProgress
          size={size ?? 16}
          className={`${errorBox} ${isWhiteSpinner ? white : ''}`}
          aria-busy="true"
          aria-describedby="loading"
        />
      </Icon>
    );
  }

  return (
    <Box className={root}>
      <CircularProgress
        className={isWhiteSpinner ? white : black}
        aria-busy="true"
        aria-describedby="loading"
      />
    </Box>
  );
}
