import '@fremtind/jkl-core/core.min.css';
import { ArrowUpRight } from '@fremtind/jkl-icons-react';
import { Button } from '@material-ui/core';
import React from 'react';
import useStyles from './ButtonStyles';

interface IExternalNavLinkButton {
  path: string;
  className: string;
  label: string;
  ariaLabel?: string;
}

export default function ExternalNavLinkButton({
  path,
  className,
  label,
  ariaLabel
}: IExternalNavLinkButton) {
  const { navLinkLabel } = useStyles();
  return (
    <Button
      classes={{ root: navLinkLabel }}
      href={path}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      aria-labelledby={ariaLabel}
    >
      {label}
      <ArrowUpRight variant="inherit" />
    </Button>
  );
}
