import { createContainer } from 'unstated-next';
import useFileAttachment from 'hooks/files/useFileAttachment';
import { BulkMoveOBERequest } from 'api';
import { vehicleDocumentationFileSizeLimit } from 'constants/fileSizeLimits';

const useBulkTransferTag = () => {
  const bulkObeAttachment = useFileAttachment<BulkMoveOBERequest>(
    vehicleDocumentationFileSizeLimit
  );

  return {
    bulkObeAttachment
  };
};

export default createContainer(useBulkTransferTag);
