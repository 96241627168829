/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMSAL } from 'auth/msalProvider';
import { CUSTOMERS } from 'constants/routes';
import { serviceOptions } from './index';
import baseURL from '../../env-config.json';

export default () => {
  const { accessToken, fetchAccessToken } = useMSAL();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const redirectTimeoutMS = 10000;

  const convertMillisecondsToSeconds = (milliseconds: number) => {
    return milliseconds / 1000;
  };

  const isUserOnCustomerProfilePage = () =>
    window.location.href.endsWith(CUSTOMERS);

  const instance: AxiosInstance = axios.create({
    baseURL: baseURL.integrations.gateway_host,
    timeout: 30000
  });

  const redirectUserToCustomerProfilePage = () => {
    enqueueSnackbar(
      t('error.redirect_to_customer_profiles_page', {
        timeoutInSec: convertMillisecondsToSeconds(redirectTimeoutMS)
      }),
      {
        variant: 'error'
      }
    );
    setTimeout(() => {
      window.location.href = CUSTOMERS;
    }, redirectTimeoutMS);
  };

  // eslint-disable-next-line no-unused-expressions
  accessToken
    ? (instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`)
    : delete instance.defaults.headers.common.Authorization;

  /*
   * This interceptor is here to handle renewal of token whenever the user gets a 401.
   * It will do a getTokenRedirect, which hopefully uses acquireTokenSilent
   * Which will not redirect the user and make him lose all data.
   */
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const { response, config } = error;

      if (response?.status === 401) {
        // Tries to refetch token
        await fetchAccessToken();
        if (accessToken) {
          instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          // Return original request.
          return axios(config);
        }
      } else if (response?.status === 403) {
        if (!isUserOnCustomerProfilePage()) {
          enqueueSnackbar(t('error.authorization'), {
            variant: 'error'
          });
          redirectUserToCustomerProfilePage();
        }
      } else if (response?.status === 404) {
        // do nothing....
      } else {
        enqueueSnackbar(t('error.something'), {
          variant: 'error'
        });
      }

      return Promise.reject(error);
    }
  );

  serviceOptions.axios = instance;
};
