import { Address } from 'api';
import { createContainer } from 'unstated-next';
import CurrentCustomerStore from './useCurrentCustomer';

const useUpdateCustomer = () => {
  const { customer, setCustomer } = CurrentCustomerStore.useContainer();

  const updateEmail = (email: string) =>
    customer &&
    setCustomer({
      ...customer,
      email
    });

  const convertedToAddress = (
    values: { [x: string]: string } | Address | null
  ): Address | null => {
    if (!values) return null;
    return {
      ...values
    };
  };

  const updateCustomerAddressProperty = (
    property: string,
    address: { [x: string]: string } | Address | null
  ) => {
    return (
      customer &&
      setCustomer({
        ...customer,
        [property]: convertedToAddress(address)
      })
    );
  };

  const updatePhoneNumber = (phoneNumber: string) =>
    customer &&
    setCustomer({
      ...customer,
      phoneNumber
    });

  return {
    updateEmail,
    updatePhoneNumber,
    updateCustomerAddressProperty
  };
};

export default createContainer(useUpdateCustomer);
