import Compressor from 'compressorjs';

const compressionQuality = 0.8;
const supportedFormats = ['image/png', 'image/jpeg'];

export const isCompressableFormat = (fileType: string) =>
  supportedFormats.indexOf(fileType.toLowerCase()) > -1;

// https://github.com/fengyuanchen/compressorjs/blob/main/README.md
export const compressImage = async (file: File) => {
  return new Promise<File | Blob>((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: compressionQuality,
      checkOrientation: false,
      success: resolve,
      error: reject
    });
  });
};
