import { Button, Container } from '@material-ui/core';
import Footer from 'components/JokulFooter/Footer';
import Navigation from 'components/Navigation/Navigation';
import { Title } from 'components/Text';
import { CUSTOMERS } from 'constants/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useStyles from './GenericErrorPageStyles';

export default function GenericErrorPage() {
  const { t } = useTranslation();
  const { container, link, content, title } = useStyles();
  const { push } = useHistory();

  const pushAndRefresh = (path: string) => {
    push(path);
    window.location.reload();
  };

  return (
    <>
      <Navigation isNavLinksEnabled={false} />

      <Container className={content} maxWidth="md">
        <Title className={title}>{t('error.something')}</Title>
        <Button className={link} onClick={() => pushAndRefresh(CUSTOMERS)}>
          {t('error.gotomainpage')}
        </Button>
      </Container>

      <Container className={container} maxWidth="md">
        <Footer />
      </Container>
    </>
  );
}
