import React from 'react';
import Spinner from 'components/Spinner/Spinner';
import IButton from 'common/interfaces/IButton';
import { SecondaryButton as JokulSecondaryButton } from '@fremtind/jkl-button-react';
import useStyles from './ButtonStyles';
import '@fremtind/jkl-button/button.min.css';
import '@fremtind/jkl-loader/loader.min.css';

export default function SecondaryButton({
  size,
  loading,
  className,
  children,
  ariaLabel,
  disabled,
  onClick,
  arrow,
  preventDefault
}: IButton) {
  const { secondaryButton, wrapper } = useStyles();

  return (
    <div className={wrapper}>
      <JokulSecondaryButton
        color="primary"
        className={`${className} ${secondaryButton}`}
        aria-labelledby={ariaLabel}
        disabled={loading || disabled}
        onClick={(e) => {
          if (preventDefault) {
            e.preventDefault();
          }
          onClick();
        }}
        forceCompact={size === 'small'}
        arrow={arrow}
      >
        {children}
      </JokulSecondaryButton>
      {loading && <Spinner isButtonProgress />}
    </div>
  );
}

SecondaryButton.defaultProps = {
  disabled: false,
  loading: false,
  className: '',
  size: undefined,
  arrow: undefined,
  preventDefault: false
};
