import React from 'react';
import { Typography } from '@material-ui/core';
import IText from './IText';

export default function BoldSubtitle({ children, align, className }: IText) {
  return (
    <Typography
      aria-label="bold subtitle"
      align={align}
      variant="subtitle2"
      className={className}
    >
      {children}
    </Typography>
  );
}

BoldSubtitle.defaultProps = {
  align: 'left'
};
