/* eslint-disable no-param-reassign */
import IAttachmentFile from 'common/interfaces/IFileAttachment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isCompressableFormat,
  compressImage
} from 'utils/compressImage/CompressImage';

export type FileAttachmentInstance<T> = {
  handleAddAttachment: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteAttachment: () => void;
  attachedFile: T | null;
};

function useFileAttachment<T extends IAttachmentFile>(
  fileSizeLimit: number
): FileAttachmentInstance<T> {
  const [attachedFile, setAttachedFile] = useState<T | null>(null);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const displayErrorFeedback = (
    event: React.ChangeEvent<HTMLInputElement>,
    message: string
  ) => {
    enqueueSnackbar(
      t(`form_file_input.${message}`, {
        fileSizeLimit: Math.floor(fileSizeLimit / 1048576)
      }),
      {
        variant: 'error'
      }
    );
    // eslint-disable-next-line no-param-reassign
    event.target.value = null; // Needed so that onChange gets triggered if the same incorrect file is selected again
  };

  const loadFileReader = (
    fileReader: FileReader,
    file: { name: string; mimeType: string }
  ) => {
    fileReader.onload = (e) => {
      const fileAsByteArray = new Uint8Array(e.target?.result as ArrayBuffer);
      setAttachedFile({
        fileName: file.name,
        mimeType: file.mimeType,
        attachment: Array.from(fileAsByteArray)
      } as T);
    };

    fileReader.onerror = () => {
      enqueueSnackbar(
        `${t(`form_file_input.error_loading_attachment`)} ${file.name}`,
        {
          variant: 'error'
        }
      );
    };
  };

  const handleAddAttachment = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileReader = new FileReader();
    const file = event.target.files[0];

    if (file == null) return;

    loadFileReader(fileReader, {
      mimeType: file.type,
      name: file.name
    });

    /* 1) If file is equal or less than 5 MB, then upload directly
     * 2) If file type is supported for compression, then try compress and then upload if result is equal or less than 5 MB
     * 3) Lastly, show error that the upload couldn't be handled due to size
     */
    if (file.size <= fileSizeLimit) {
      fileReader.readAsArrayBuffer(file);
    } else if (isCompressableFormat(file.type)) {
      try {
        const compressedImage = await compressImage(file);

        if (compressedImage.type !== file.type) {
          loadFileReader(fileReader, {
            mimeType: compressedImage.type,
            name:
              compressedImage.name !== undefined
                ? compressedImage.name
                : file.name
          });
        }

        if (compressedImage.size <= fileSizeLimit) {
          fileReader.readAsArrayBuffer(compressedImage);
        } else {
          displayErrorFeedback(event, 'compressed_still_above_limit');
        }
      } catch (error) {
        displayErrorFeedback(event, 'compression_failed');
      }
    } else {
      displayErrorFeedback(event, 'file_size_limit');
    }
  };

  const handleDeleteAttachment = () => {
    setAttachedFile(null);
  };

  return {
    handleAddAttachment,
    handleDeleteAttachment,
    attachedFile
  };
}

export default useFileAttachment;
