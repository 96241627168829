import { Button } from '@material-ui/core';
import { CloudUpload, Remove } from '@material-ui/icons';
import IAttachmentFile from 'common/interfaces/IFileAttachment';
import { FileAttachmentInstance } from 'hooks/files/useFileAttachment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './ButtonStyles';

type FileUploadButtonProps<T> = {
  fileAttachmentInstance: FileAttachmentInstance<T>;
  label?: string;
  name?: string;
  inputRef?: any;
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  accepts: string;
};

function FileUploadButton<T extends IAttachmentFile>({
  fileAttachmentInstance,
  label,
  name,
  inputRef,
  className,
  color,
  accepts
}: FileUploadButtonProps<T>) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    attachedFile,
    handleAddAttachment,
    handleDeleteAttachment
  } = fileAttachmentInstance;

  if (attachedFile !== null && attachedFile.fileName !== null) {
    return (
      <Button
        variant="contained"
        color={color ?? 'primary'}
        component="span"
        className={`${className} ${classes.fileButton}`}
        startIcon={<Remove />}
        onClick={handleDeleteAttachment}
      >
        {attachedFile?.fileName}
      </Button>
    );
  }

  return (
    <>
      <input
        accept={accepts}
        className={classes.fileInput}
        id="attachment-button-file"
        multiple
        type="file"
        name={name}
        ref={inputRef}
        onChange={handleAddAttachment}
      />
      <label htmlFor="attachment-button-file">
        <Button
          variant="contained"
          color={color ?? 'primary'}
          component="span"
          className={`${className} ${classes.fileButton}`}
          startIcon={<CloudUpload />}
        >
          {label ?? t('form_file_input.upload_file')}
        </Button>
      </label>
    </>
  );
}

export default FileUploadButton;
