import React from 'react';
import IIcon from 'common/interfaces/IIcon';
import { DARK_GRAY } from 'constants/colors';

export default function NoVehicle({ fill }: IIcon) {
  return (
    <svg
      width="47"
      height="44"
      viewBox="0 0 47 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6867 9.18467C35.32 8.10301 34.2933 7.33301 33.0833 7.33301H12.9167C11.7067 7.33301 10.6983 8.10301 10.3133 9.18467L6.5 20.1663V34.833C6.5 35.8413 7.325 36.6663 8.33333 36.6663H10.1667C11.175 36.6663 12 35.8413 12 34.833V32.9997H34V34.833C34 35.8413 34.825 36.6663 35.8333 36.6663H37.6667C38.675 36.6663 39.5 35.8413 39.5 34.833V20.1663L35.6867 9.18467ZM13.5583 10.9997H32.4233L34.4033 16.7013H11.5783L13.5583 10.9997ZM10.1667 29.333H35.8333V20.1663H10.1667V29.333ZM14.75 21.9997C13.2312 21.9997 12 23.2309 12 24.7497C12 26.2685 13.2312 27.4997 14.75 27.4997C16.2688 27.4997 17.5 26.2685 17.5 24.7497C17.5 23.2309 16.2688 21.9997 14.75 21.9997ZM28.5 24.7497C28.5 23.2309 29.7312 21.9997 31.25 21.9997C32.7688 21.9997 34 23.2309 34 24.7497C34 26.2685 32.7688 27.4997 31.25 27.4997C29.7312 27.4997 28.5 26.2685 28.5 24.7497Z"
        fill={fill}
      />
      <line
        x1="3.17008"
        y1="39.1767"
        x2="44.1767"
        y2="2.82992"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

NoVehicle.defaultProps = {
  fill: DARK_GRAY
};
