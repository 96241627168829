import React from 'react';
import { Typography } from '@material-ui/core';
import IText from './IText';

export default function Subtitle({ className, children, align }: IText) {
  return (
    <Typography
      className={className}
      aria-label="subtitle"
      align={align}
      variant="subtitle1"
    >
      {children}
    </Typography>
  );
}

Subtitle.defaultProps = {
  align: 'left',
  className: ''
};
