import { useState } from 'react';
import { createContainer } from 'unstated-next';

import { Address } from 'api';
import addressTypes from 'constants/addressTypes';

function useAddress() {
  const [currentAddressType, setAddressType] = useState<string>(
    addressTypes.CURRENT
  );
  const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>(
    undefined
  );

  const [hasErrors, setHasErrors] = useState<boolean>(false);

  // Set standard delivery address checkbox value
  const [isStandardDeliveryAddress, setIsStandardDeliveryAddress] = useState<
    boolean
  >(false);

  const handleStandardDeliveryAddress = (event: React.ChangeEvent<unknown>) => {
    const e = event.target as HTMLInputElement;
    setIsStandardDeliveryAddress(e.checked);
  };

  const resetAddress = () => {
    setHasErrors(false);
    setAddressType(addressTypes.CURRENT);
    setDeliveryAddress(undefined);
    setIsStandardDeliveryAddress(false);
  };

  return {
    currentAddressType,
    setAddressType,
    hasErrors,
    setHasErrors,
    resetAddress,
    deliveryAddress,
    setDeliveryAddress,
    isStandardDeliveryAddress,
    handleStandardDeliveryAddress
  };
}

export default createContainer(useAddress);
