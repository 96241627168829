/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '/api';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CustomerService {
  /**
   * Search for customers using customer data.
   */
  static customerSearch(
    params: {
      /** Customer Search Query */
      body: CustomerQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/search';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get data for a single customer.
   */
  static getCustomer(
    params: {
      /** GUID for the customer that is to be retrieved */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Customer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit customer
   */
  static editCustomer(
    params: {
      /** GUID for the customer that is to be updated */
      customerId: string;
      /** Customer data */
      body: CustomerCreateData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Customer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Filter and retrieve invoices for a single customer.
   */
  static getInvoices(
    params: {
      /** GUID for the customer whose invoices should be retrieved */
      customerId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body?: InvoiceCustomerQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerInvoices> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/invoices';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Filter and retrieve transactions for a single customer.
   */
  static getTransactions(
    params: {
      /** GUID for the customer */
      customerId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerTransactions> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/transactions';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Filter and retrieve passings for a single customer.
   */
  static getPassings(
    params: {
      /** GUID for the customer */
      customerId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body: PassingsDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPassings> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/passings';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Filter and retrieve uninvoiced passings for a single customer.
   */
  static getUninvoicedPassings(
    params: {
      /** GUID for the customer */
      customerId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPassings> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/passings/uninvoiced';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a list of all existing Customer Groups
   */
  static getCustomerGroups(
    options: IRequestOptions = {}
  ): Promise<CustomerGroup[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customerGroups';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new customer
   */
  static createNewCustomer(
    params: {
      /** Customer data */
      body: CustomerCreateData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Customer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/create';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all customers the current user have access to
   */
  static getCustomersByAccessToken(
    options: IRequestOptions = {}
  ): Promise<Customer[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customers';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerInvoicesUnpaidService {
  /**
   * Get unpaid invoices by customerId
   */
  static getUnpaidInvoicesByCustomerId(
    params: {
      /** GUID for the customer the invoices must be retrieved for */
      customerId: string;
      /** date and pagination query */
      body?: GetUnpaidInvoicesSearchRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedInvoiceSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/invoices/unpaid';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerInvoicesUnpaidV2Service {
  /**
   * Get unpaid invoices by customerId
   */
  static getUnpaidInvoicesByCustomerIdv2(
    params: {
      /** GUID for the customer the invoices must be retrieved for */
      customerId: string;
      /** date and pagination query */
      body?: GetUnpaidInvoicesSearchRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SimpleInvoiceSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/invoices/unpaid/v2';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerInvoicesNumberUnpaidService {
  /**
   * Get number of unpaid invoices by customerId
   */
  static getNumberOfUnpaidInvoicesByCustomerId(
    params: {
      /** GUID for the customer the invoices must be retrieved for */
      customerId: string;
      /** date and pagination query */
      body?: GetUnpaidInvoicesSearchRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/invoices/number/unpaid';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerInvoicesPaidService {
  /**
   * Get paid invoices by customerId
   */
  static getPaidInvoicesByCustomerId(
    params: {
      /** GUID for the customer the invoices must be retrieved for */
      customerId: string;
      /** date and pagination query */
      body?: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedInvoiceSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/invoices/paid';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InvoiceService {
  /**
   * Get all invoice versions by customerNumber and invoiceNumber
   */
  static getInvoiceHistory(
    params: {
      /** Customer number for the customer who retrieved the invoice */
      customerNumber: string;
      /** Invoice number for the invoice to be retrieved */
      invoiceNumber: string;
      /** date and pagination query */
      body?: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceHotelSearchResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/customer/{customerNumber}/invoice/{invoiceNumber}/getInvoiceHistory';
      url = url.replace('{customerNumber}', params['customerNumber'] + '');
      url = url.replace('{invoiceNumber}', params['invoiceNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get details about an invoice using invoice number.
   */
  static getInvoice(
    params: {
      /** Invoice Number for the desired invoice. */
      invoiceNumber: string;
      /** For exclude invoice lines */
      body: GetInvoiceByInvoiceNumberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedInvoice> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoice/fetch/{invoiceNumber}';
      url = url.replace('{invoiceNumber}', params['invoiceNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Activate blocking of dunning actions
   */
  static blockDunning(
    params: {
      /** Invoice Number for the desired invoice. */
      invoiceNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoice/{invoiceNumber}/blockDunning';
      url = url.replace('{invoiceNumber}', params['invoiceNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Deactivate blocking of dunning actions
   */
  static unblockDunning(
    params: {
      /** Invoice Number for the desired invoice. */
      invoiceNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoice/{invoiceNumber}/unblockDunning';
      url = url.replace('{invoiceNumber}', params['invoiceNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download attachment
   */
  static getInvoiceAttachment(
    params: {
      /** A unique file name with file type extension. */
      fileName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoice/attachment/{fileName}';
      url = url.replace('{fileName}', params['fileName'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search for invoices in Invoice hotel from TietoEvry
   */
  static searchInvoiceHotel(
    params: {
      /** Invoice hotel Search Request */
      body: InvoiceHotelSearchQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceHotelSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoice/searchInvoiceHotel';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Postpone due date
   */
  static postponeDueDate(
    params: {
      /**  */
      body: PostponeInvoiceDueDateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PostponeInvoiceDueDateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoice/postponeDueDate';

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BankReferenceService {
  /**
   * Create bank reference with using authId, referenceId and status.
   */
  static createBankReference(
    params: {
      /** parameters required to create or update bank reference */
      body: CreateOrUpdateBankReference;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateOrUpdateBankReference> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bankReference/create';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get bank reference by authId
   */
  static getBankReference(
    options: IRequestOptions = {}
  ): Promise<CreateOrUpdateBankReference> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bankReference/get';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get bank details by bank short name
   */
  static getSpareBank(
    params: {
      /** A short name of a sparebank containing 3-4 letters */
      shortName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpareBank> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/spareBank/{shortName}';
      url = url.replace('{shortName}', params['shortName'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AccountService {
  /**
   * Create account with a CustomerId Guid.
   */
  static createAccount(
    params: {
      /** GUID for the customer who needs an new account */
      body: CreateAccountQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountId> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/account/create';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgreementsService {
  /**
   * Get agreements for a customer.
   */
  static getAgreementsForCustomer(
    params: {
      /** GUID for the customer we gather agreements for */
      customerId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body: AgreementCustomerQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerAgreementsSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/agreements';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get agreements with its vehicles for a customer.
   */
  static getAgreementsWithObEsAndVehiclesForCustomer(
    params: {
      /** GUID for the customer we gather agreements for */
      customerId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerAgreementsWithOBEsAndVehiclesSearchResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/customer/{customerId}/agreementsWithOBEsAndVehicles';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search for agreements.
   */
  static agreementSearch(
    params: {
      /** Agreement Search Request */
      body: SearchAgreementsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgreementSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/search';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get agreement by ID
   */
  static getAgreementById(
    params: {
      /** GUID for the agreement that is to be retrieved */
      agreementId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agreement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit Agreement
   */
  static editAgreement(
    params: {
      /** GUID for the agreement that is to be retrieved */
      agreementId: string;
      /** agreement data */
      body: UpdateAgreementRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agreement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get agreement by agreement number
   */
  static getAgreementByAgreementNumber(
    params: {
      /** Agreement number for the agreement that is to be retrieved */
      agreementNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SimpleAgreement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/agreementNumber/{agreementNumber}';
      url = url.replace('{agreementNumber}', params['agreementNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Manually trigger invoice generation for the agreement.
   */
  static generateInvoice(
    params: {
      /** GUID for the agreement to generate an invoice on. */
      agreementId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}/generateInvoice';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create agreement with a CustomerId Guid and Name.
   */
  static createAgreement(
    params: {
      /** CustomerId and Name who needs an new agreement */
      body: AgreementRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgreementIdAndNumber> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/create';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Reject agreement
   */
  static rejectAgreement(
    params: {
      /** GUID for the agreement that is to be rejected */
      agreementId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}/reject';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Approve agreement
   */
  static approveAgreements(
    params: {
      /** GUIDs for the agreements that is to be approved */
      body: ApproveRejectAgreementsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/approve';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Cancel agreement
   */
  static cancelAgreement(
    params: {
      /** GUID for the agreement that is to be cancel with a reason and related case number */
      body: CancelAgreementsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/cancel';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgreementsInvoicesService {
  /**
   * Get Invoices by AgreementID
   */
  static getInvoicesByAgreementId(
    params: {
      /** GUID for the agreement the invoices must be retrieved for */
      agreementId: string;
      /** date and pagination query */
      body?: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedInvoiceSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}/invoices';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgreementsInvoicesUnpaidService {
  /**
   * Get unpaid invoices by AgreementID
   */
  static getUnpaidInvoicesByAgreementId(
    params: {
      /** GUID for the agreement the invoices must be retrieved for */
      agreementId: string;
      /** date and pagination query */
      body?: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedInvoiceSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}/invoices/unpaid';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgreementsPassingsService {
  /**
   * Get passings by AgreementID
   */
  static getPassingsByAgreementId(
    params: {
      /** GUID for the agreement the invoices must be retrieved for */
      agreementId: string;
      /** date and pagination query */
      body?: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PassingSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}/passings';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InvoicePassingsService {
  /**
   * Get passings by InvoiceId
   */
  static getPassingsByInvoiceId(
    params: {
      /** GUID for the invoice the passings must be retrived from */
      invoiceId: string;
      /** date, pagination, Obe number and reg. number query */
      body?: GetPassingsByInvoiceIdSearchRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PassingSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoice/{invoiceId}/passings';
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ObeService {
  /**
   * Get OBEs for a customer
   */
  static getObesWithQuery(
    params: {
      /** GUID for the customer that is to be retrieved */
      customerId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body?: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetOBEsResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/getByCustomerId/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get OBEs for a customer
   */
  static getObes(
    params: {
      /** GUID for the customer that is to be retrieved */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetOBEsResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/getByCustomerId/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get monthly OBE subscription fee
   */
  static getMonthlyObeFee(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obeSubscriptionFeePrice';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get OBE details for a OBE
   */
  static getObeDetails(
    params: {
      /** Obe number for details on a OBE */
      obeNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetObeDetailsResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/{obeNumber}';
      url = url.replace('{obeNumber}', params['obeNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Return obes
   */
  static returnObes(
    params: {
      /** OBEs to be returned */
      body: ReturnOBERequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReturnOBEResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/return';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Claim OBE deposit
   */
  static claimDeposit(
    params: {
      /** Claim deposit request data */
      body: ClaimOBEDepositRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetObeDetailsResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/claimDeposit';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get OBE by agreement ID
   */
  static getObesByAgreementId(
    params: {
      /** GUID for the agreement that is to be retrieved */
      agreementId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetOBEandVehicleResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/getByAgreementId/{agreementId}';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get OBE with vehicle data by agreement ID
   */
  static getObesWithVehicleByAgreementId(
    params: {
      /** GUID for the agreement that is to be retrieved */
      agreementId: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetOBEandVehicleResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/getWithVehicleByAgreementId/{agreementId}';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit OBE and Vehicle
   */
  static editObe(
    params: {
      /** GUID for the OBE that should be edited */
      obeId: string;
      /** Fields that needs to be edited */
      body: UpdateObeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/update/{obeId}';
      url = url.replace('{obeId}', params['obeId'] + '');

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get vehicle information from vehicle registry
   */
  static getVehicleInformation(
    params: {
      /** Owner data */
      body: GetVehicleInformationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetVehicleInformationResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/GetVehicleInformation';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get inactive OBEs
   */
  static getInactiveObes(
    params: {
      /** GUID for the customer that has its Inactive OBEs retrieved */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetObeDetailsResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/obes/inactive';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Replace an OBE
   */
  static replaceObe(
    params: {
      /**  */
      body: ReplaceObeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/replace';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaymentService {
  /**
   * Submit manual payment
   */
  static manualPayment(
    params: {
      /** GUID for the agreement where the manual payment is performed */
      agreementId: string;
      /** Payment details, amount, invoice number etc. */
      body: ManualPaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manualPayment/{agreementId}';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CaseService {
  /**
   * Get case by id
   */
  static getCase(
    params: {
      /** GUID for a case. */
      caseId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Case> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/{caseId}';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search for cases using case type.
   */
  static caseSearch(
    params: {
      /** Case Search Request */
      body: CaseSearchRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/search';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new case.
   */
  static createCase(
    params: {
      /** Create Case Request */
      body: CreateCaseRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateCaseResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/create';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new international case.
   */
  static createInternationalCase(
    params: {
      /** Create International Case Request */
      internationalBody: CreateInternationalCaseRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateCaseResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/international-case/create';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['internationalBody'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update existing case.
   */
  static updateCase(
    params: {
      /** GUID for a case. */
      caseId: string;
      /** Update Case Request */
      body: UpdateCaseRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/{caseId}/update';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets a case with all its comments.
   */
  static getCaseWithComments(
    params: {
      /** GUID for a case. */
      caseId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseWithCommentsResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/{caseId}/comments';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Searches comments related to a case.
   */
  static searchCommentsForCase(
    params: {
      /** GUID for a case. */
      caseId: string;
      /** Generic date search request */
      body: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseCommentSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/{caseId}/comments';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a new case comment
   */
  static createCommentForCase(
    params: {
      /** GUID for a case. */
      caseId: string;
      /** Create case comment request */
      body: CreateCaseCommentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseCommentResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/{caseId}/comment/create';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets a specific case comment.
   */
  static getCaseComment(
    params: {
      /** GUID for a case. */
      caseId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseCommentResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/comment/{caseCommentId}';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates a new case comment attachment
   */
  static createCaseCommentAttachment(
    params: {
      /** GUID for a case comment. */
      caseCommentId: string;
      /** Create case comment attachment request */
      body: CreateCaseCommentAttachmentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseCommentAttachmentResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/comment/{caseCommentId}/attachment/create';
      url = url.replace('{caseCommentId}', params['caseCommentId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download case comment attachment
   */
  static downloadCaseCommentAttachmentById(
    params: {
      /** The id of the Case Comment Attachment */
      caseCommentAttachmentId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/case/comment/attachment/{caseCommentAttachmentId}/download';
      url = url.replace(
        '{caseCommentAttachmentId}',
        params['caseCommentAttachmentId'] + ''
      );

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get case comment attachment
   */
  static getCaseCommentAttachmentById(
    params: {
      /** The id of the Case Comment Attachment */
      caseCommentAttachmentId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseCommentAttachmentResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/comment/attachment/{caseCommentAttachmentId}';
      url = url.replace(
        '{caseCommentAttachmentId}',
        params['caseCommentAttachmentId'] + ''
      );

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Complete unknown payment
   */
  static completeUnknownPayment(
    params: {
      /** GUID for the case */
      caseId: string;
      /**  */
      body: CompleteUnknownPaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/{caseId}/completeUnknownPayment';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a customers case by case number
   */
  static caseForCustomer(
    params: {
      /** Case number for a case. */
      caseNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseLookupResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/forCustomer/{caseNumber}';
      url = url.replace('{caseNumber}', params['caseNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Remit a payment
   */
  static remitUnknownPayment(
    params: {
      /** GUID for the case */
      caseId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/{caseId}/remitUnknownPayment';
      url = url.replace('{caseId}', params['caseId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PassingsService {
  /**
   * Get passing by ID
   */
  static getPassingById(
    params: {
      /** GUID for the passing that is to be retrieved */
      passingId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Passing> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/passing/{passingId}';
      url = url.replace('{passingId}', params['passingId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Filter and retrieve passings for a given OBE number.
   */
  static getPassingsForObe(
    params: {
      /** OBE number */
      obeNumber: string;
      /** Filter query for controlling dateFrom, dateTo, max, offset etc. */
      body: GenericDateAndPaginationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerPassings> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/passings/{obeNumber}';
      url = url.replace('{obeNumber}', params['obeNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VehicleService {
  /**
   * Register vehicle
   */
  static registerVehicle(
    params: {
      /** Vehicle data */
      body: RegisterVehicleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicle/register';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Register vehicle and transfer existing OBE to it
   */
  static registerVehicleWithObe(
    params: {
      /** Vehicle data */
      body: RegisterVehicleWithObeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicle/registerWithObe';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Register vehicle, place order for new OBE and create case
   */
  static registerVehicleAndCreateCase(
    params: {
      /** Vehicle, case and order data */
      body: RegisterVehicleCreateCaseRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicle/registerAndCreateCase';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Unregister vehicle and remove or keep OBE, option in Request body
   */
  static unregisterVehicle(
    params: {
      /** Unregister vehicle data */
      body: UnregisterVehicleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicle/unregister';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets vehicle by its registration number and country code
   */
  static getVehicleByRegNumberAndCountryCode(
    params: {
      /** Vehicle's registration number */
      regNumber: string;
      /** Vehicle's country code */
      countryCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/vehicle/getByRegNumberAndCountryCode/{regNumber}/{countryCode}';
      url = url.replace('{regNumber}', params['regNumber'] + '');
      url = url.replace('{countryCode}', params['countryCode'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Checks if the vehicle exists by using registration number and country code
   */
  static checkVehicleByRegNumberAndCountryCode(
    params: {
      /** Vehicle's registration number */
      regNumber: string;
      /** Vehicle's country code */
      countryCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckVehicleResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/vehicle/checkVehicleByRegNumberAndCountryCode/{regNumber}/{countryCode}';
      url = url.replace('{regNumber}', params['regNumber'] + '');
      url = url.replace('{countryCode}', params['countryCode'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Unregister vehicles and remove or keep OBE, option in Request body
   */
  static unregisterVehicles(
    params: {
      /** Unregister vehicles data */
      body: UnregisterVehiclesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/unregister';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Downloads documentation file for a foreign vehicle
   */
  static downloadVehicleDocumentationById(
    params: {
      /** The id of the vehicle documentation */
      vehicleDocumentationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/vehicle/documentation/{vehicleDocumentationId}/download';
      url = url.replace(
        '{vehicleDocumentationId}',
        params['vehicleDocumentationId'] + ''
      );

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Gets documentation file for a foreign vehicle
   */
  static getVehicleDocumentationById(
    params: {
      /** The id of the vehicle documentation */
      vehicleDocumentationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleDocumentationResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicle/documentation/{vehicleDocumentationId}';
      url = url.replace(
        '{vehicleDocumentationId}',
        params['vehicleDocumentationId'] + ''
      );

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ObeVehicleService {
  /**
   * Move OBE from one vehicle to another and/or to another agreement
   */
  static moveObe(
    params: {
      /** GUID for OBE to move. GUID for target vehicle. Optionally GUID for target agreement. Optional string for new agreement name and GUID customerId for the new agreement. */
      body: MoveObeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/move';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Move OBE from one vehicle to another and/or to another agreement in bulk using a file
   */
  static moveObeBulk(
    params: {
      /**  */
      body: BulkMoveOBERequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BulkMoveOBEResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/move/bulk';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompanyOrganizationService {
  /**
   * Search in company registry (Brønnøysundregistrene)
   */
  static searchOrganizationInformation(
    params: {
      /** Organization name or number */
      body: OrganizationSearch;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organization-information';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PeopleRegistryService {
  /**
   * Check in people registry (DSF) if given person exists
   */
  static checkIfPersonExists(
    params: {
      /** Person's name, surname and birth date (first 6 digits of national identity number) */
      body: CheckIfPersonExistsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/checkIfPersonExists';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ShippingService {
  /**
   * Get order by id
   */
  static getOrder(
    params: {
      /** GUID for an order. */
      orderId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders/{orderId}';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get label by order id
   */
  static getOrderLabel(
    params: {
      /** Number of an OBE */
      obeNumber?: string;
      /** GUID for an order. */
      orderId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders/{orderId}/label';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );
      configs.params = { obeNumber: params['obeNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates an order
   */
  static createOrder(
    params: {
      /** Create Order Request */
      body: CreateOrderRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search for orders using order type.
   */
  static searchOrder(
    params: {
      /** Order Search Request */
      body: OrderSearchRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders/search';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Start the scanning process of an order
   */
  static startOrder(
    params: {
      /** GUID for an order. */
      orderId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders/{orderId}/start';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Stop the scanning process of an order
   */
  static stopOrder(
    params: {
      /** GUID for an order. */
      orderId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders/{orderId}/stop';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Complete the scanning process of an order
   */
  static completeOrder(
    params: {
      /** GUID for an order. */
      orderId: string;
      /** Order complete request */
      body: OrderCompleteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders/{orderId}/complete';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get the order item by item number
   */
  static getOrderItem(
    params: {
      /** Item number for an order item. */
      itemNumber: string;
      /** Status of inventory item */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orderItems/{itemNumber}';
      url = url.replace('{itemNumber}', params['itemNumber'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );
      configs.params = { status: params['status'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search for pending orders using customer.
   */
  static searchCustomerOrder(
    params: {
      /** Search Request */
      body: GenericPaginationQuery;
      /** GUID for an customer. */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/shipping/orders/customer/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BlankObEsService {
  /**
   * Register many blank OBEs
   */
  static registerBlankObes(
    params: {
      /** A list of ObeNumbers, Agreement information and Vehicle information */
      body: RegisterVehiclesAndObesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/obe/register-blank-obes';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RemittanceService {
  /**
   * Approve remittance
   */
  static approveRemittance(
    params: {
      /** Update remittance status request */
      body: UpdateRemittanceStatusRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/remittance/approve';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Reject remittance
   */
  static rejectRemittance(
    params: {
      /** Update remittance status request */
      body: UpdateRemittanceStatusRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/remittance/reject';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Manually trigger remittance of overpayments and credit transactions
   */
  static generateRemittanceForAgreement(
    params: {
      /** GUID for the agreement to generate remittance for. */
      agreementId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/agreement/{agreementId}/generateInvoice/remittance';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve the remittance amount for a given agreement
   */
  static getRemittanceAmountForAgreement(
    params: {
      /** GUID for the agreement to get the remittance amount for. */
      agreementId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agreement/{agreementId}/remittanceAmount';
      url = url.replace('{agreementId}', params['agreementId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TransactionsService {
  /**
   * Get transaction by ID
   */
  static getTransactionById(
    params: {
      /** GUID for the transaction that is to be retrieved */
      transactionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Transaction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transaction/{transactionId}';
      url = url.replace('{transactionId}', params['transactionId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get transaction by ID for Case Details
   */
  static getRemittanceTransactionForCaseDetailsById(
    params: {
      /** GUID for the transaction that is to be retrieved */
      transactionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RemittanceTransaction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/case/transaction/{transactionId}';
      url = url.replace('{transactionId}', params['transactionId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CreditingService {
  /**
   * Creates a CreditNote case.
   */
  static creditInvoiceLines(
    params: {
      /** Manual crediting of invoicelines Request */
      body: CreditInvoiceLineRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoices/creditInvoiceLines';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Approves or rejects a CreditNote request.
   */
  static approveRejectCreditNoteCase(
    params: {
      /** Approve/Reject credit request */
      body: ApproveRejectCreditRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoices/creditInvoiceLines/decide';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   * Get user by accessToken
   */
  static getUserByAccessToken(options: IRequestOptions = {}): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/getByAccessToken';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Register a new user
   */
  static registerUser(
    params: {
      /**  */
      body: RegisterUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/register';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Recovers access to an existing user
   */
  static restoreAccess(
    params: {
      /**  */
      body: RestoreAccessRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user/restoreAccess';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CityService {
  /**
   * Get city name by postal code
   */
  static getCity(
    params: {
      /** Postal code for city */
      postalCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityAddressResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/getCity/{postalCode}';
      url = url.replace('{postalCode}', params['postalCode'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PrivilegeService {
  /**
   * Activate a privilege for a user
   */
  static activatePrivilege(
    params: {
      /**  */
      body: ActivatePrivilegeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/activate';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search privileges for customer pending activation
   */
  static searchPendingPrivileges(
    params: {
      /** Customer GUID */
      customerId: string;
      /** Search request for pending privilege */
      body: SearchPrivilegeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchPendingPrivilegesResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/pending/search/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search privileges for customer
   */
  static searchPrivileges(
    params: {
      /** Customer GUID */
      customerId: string;
      /** Search request for privilege */
      body: SearchPrivilegeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchPrivilegesResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/search/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get privileges for customer pending activation
   */
  static getPendingPrivileges(
    params: {
      /** Customer GUID */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivilegeGrantTokenModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/pending/customer/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get privileges for customer
   */
  static getPrivileges(
    params: {
      /** Customer GUID */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Privilege[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/customer/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Grant privilege to user
   */
  static grantPrivilege(
    params: {
      /**  */
      body: GrantPrivilegeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/grant';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Revoke privilege
   */
  static revokePrivilege(
    params: {
      /** Privilege GUID */
      privilegeId: string;
      /**  */
      body: RevokePrivilegeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/revokeById/{privilegeId}';
      url = url.replace('{privilegeId}', params['privilegeId'] + '');

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get pending privilege by privilegeGrantToken
   */
  static getPendingPrivilege(
    params: {
      /** Privilege grant token */
      token: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivilegeGrantTokenModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/privilege/pending/withPrivilegeGrantToken/{token}';
      url = url.replace('{token}', params['token'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Check if there already are users that have privileges to the customer
   */
  static checkIfPrivilegesAlreadyExists(
    params: {
      /** Privilege grant token */
      privilegeGrantToken: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivilegesExistsResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/privilege/privilegesForCustomerAlreadyExists/{privilegeGrantToken}';
      url = url.replace(
        '{privilegeGrantToken}',
        params['privilegeGrantToken'] + ''
      );

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InventoryService {
  /**
   * Upload obes as inventory entries.
   */
  static uploadInventoryEntries(
    params: {
      /** Upload request */
      body: UploadInventoryEntriesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadInventoryEntriesResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/inventory/upload';

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      );

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface CustomerQuery {
  /**  */
  name?: string;

  /**  */
  customerNumber?: string;

  /**  */
  socialSecurityNumber?: string;

  /**  */
  email?: string;

  /**  */
  phonenumber?: string;

  /**  */
  organizationNumber?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  postalcode?: string;

  /**  */
  city?: string;

  /**  */
  country?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  obeNumber?: string;

  /**  */
  vehicleRegistrationNumber?: string;

  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  status?: string;

  /**  */
  orderBy?: string;

  /**  */
  orderDescending?: boolean;
}

export interface CustomerAgreementsSearchResult {
  /**  */
  results?: Agreement[];

  /**  */
  totalResults?: number;
}

export interface CustomerAgreementsWithOBEsAndVehiclesSearchResult {
  /**  */
  results?: AgreementWithOBEsAndVehicles[];

  /**  */
  totalResults?: number;
}

export interface CustomerSearchResult {
  /**  */
  results?: Customer[];

  /**  */
  totalResults?: number;
}

export interface CustomerCreateData {
  /**  */
  customerStatus?: string;

  /**  */
  customerType?: EnumCustomerCreateDataCustomerType;

  /**  */
  customerGroupId?: string;

  /**  */
  email?: string;

  /**  */
  billingEmail?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  organizationName?: string;

  /**  */
  organizationNumber?: string;

  /**  */
  contactName?: string;

  /**  */
  contactEmail?: string;

  /**  */
  contactPhoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  EANNumber?: string;

  /**  */
  PONumber?: string;

  /**  */
  termsNet?: number;

  /**  */
  VATPauerId?: string;

  /**  */
  legacyId?: string;

  /**  */
  personNumber?: string;

  /**  */
  personFirstName?: string;

  /**  */
  personLastName?: string;

  /**  */
  gender?: string;

  /**  */
  customerAddress?: CreateAddress;

  /**  */
  billingAddress?: CreateAddress;

  /**  */
  shippingAddress?: CreateAddress;
}

export interface Agreement {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  status?: EnumAgreementStatus;

  /**  */
  source?: string;

  /**  */
  agreementNumber?: string;

  /**  */
  accountRef?: string;

  /**  */
  customerRef?: string;

  /**  */
  enteredOn?: string;

  /**  */
  statusChangedOn?: string;

  /**  */
  terminatedOn?: string;

  /**  */
  updatedOn?: string;

  /**  */
  createdOn?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  accountBalance?: number;

  /**  */
  accountCurrency?: string;

  /**  */
  customerName?: string;

  /**  */
  customerNumber?: string;

  /**  */
  customerIdentification?: string;

  /**  */
  customerGroupName?: string;

  /**  */
  customerNumberOfAgreements?: number;

  /**  */
  customerType?: string;
}

export interface SimpleAgreement {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  status?: EnumSimpleAgreementStatus;

  /**  */
  accountId?: string;

  /**  */
  customerId?: string;

  /**  */
  agreementNumber?: string;
}

export interface AgreementWithOBEsAndVehicles {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  status?: EnumAgreementWithOBEsAndVehiclesStatus;

  /**  */
  agreementNumber?: string;

  /**  */
  accountRef?: string;

  /**  */
  accountBalance?: number;

  /**  */
  accountCurrency?: string;

  /**  */
  customerRef?: string;

  /**  */
  enteredOn?: string;

  /**  */
  statusChangedOn?: string;

  /**  */
  terminatedOn?: string;

  /**  */
  updatedOn?: string;

  /**  */
  createdOn?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  obes?: OBEWithVehicle[];
}

export interface UpdateAgreementRequest {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  agreementNumber?: string;

  /**  */
  enteredOn?: string;

  /**  */
  updatedOn?: string;

  /**  */
  bankAccountNumber?: string;
}

export interface OBEWithVehicle {
  /**  */
  obeId?: string;

  /**  */
  obeNumber?: string;

  /**  */
  obeStatus?: string;

  /**  */
  obeBatteryStatus?: string;

  /**  */
  vehicle?: Vehicle;
}

export interface DetailedInvoiceSearchResult {
  /**  */
  results?: DetailedInvoice[];

  /**  */
  totalResults?: number;
}

export interface SimpleInvoiceSearchResult {
  /**  */
  results?: SimpleInvoice[];

  /**  */
  totalResults?: number;
}

export interface PassingSearchResult {
  /**  */
  results?: Passing[];

  /**  */
  totalResults?: number;
}

export interface Customer {
  /**  */
  id?: string;

  /**  */
  customerNumber?: string;

  /**  */
  customerStatus?: EnumCustomerCustomerStatus;

  /**  */
  customerType?: EnumCustomerCustomerType;

  /**  */
  customerGroup?: CustomerGroup;

  /**  */
  email?: string;

  /**  */
  billingEmail?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  organizationName?: string;

  /**  */
  organizationNumber?: string;

  /**  */
  contactName?: string;

  /**  */
  contactEmail?: string;

  /**  */
  contactPhoneNumber?: string;

  /**  */
  personFirstName?: string;

  /**  */
  personLastName?: string;

  /**  */
  personNumber?: string;

  /**  */
  customerAddress?: Address;

  /**  */
  billingAddress?: Address;

  /**  */
  shippingAddress?: Address;

  /**  */
  updatedOn?: string;

  /**  */
  createdOn?: string;

  /**  */
  activeAgreementsCount?: number;
}

export interface CustomerInvoices {
  /**  */
  results?: SimpleInvoice[];

  /**  */
  totalResults?: number;
}

export interface Address {
  /**  */
  id?: string;

  /**  */
  careOfName?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  postalCode?: string;

  /**  */
  postalDistrict?: string;

  /**  */
  countryCode?: string;
}

export interface CityAddressResult {
  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  city?: string;

  /**  */
  category?: string;
}

export interface CreateAddress {
  /**  */
  careOfName?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  postalCode?: string;

  /**  */
  postalDistrict?: string;

  /**  */
  countryCode?: string;
}

export interface CustomerGroup {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  premiumSelfServiceEnabled?: boolean;
}

export interface AccountId {
  /**  */
  id?: string;
}

export interface CreateAccountQuery {
  /**  */
  customerId?: string;
}

export interface CreateOrUpdateBankReference {
  /**  */
  userId?: string;

  /**  */
  referenceId?: string;

  /**  */
  status?: EnumCreateOrUpdateBankReferenceStatus;
}

export interface SpareBank {
  /**  */
  id?: string;

  /**  */
  createdOn?: string;

  /**  */
  updated?: string;

  /**  */
  shortName?: string;

  /**  */
  name?: string;
}

export interface AgreementRequest {
  /**  */
  customerId?: string;

  /**  */
  name?: string;

  /**  */
  registrationNumber?: string;

  /**  */
  nationality?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  classification?: string;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  validFrom?: string;

  /**  */
  validTo?: string;

  /**  */
  euClass?: string;

  /**  */
  co2?: string;

  /**  */
  fuelType?: string;

  /**  */
  modelYear?: Date;

  /**  */
  shippingAddress?: Address;

  /**  */
  setShippingAddressAsStandard?: boolean;

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];
}

export interface AgreementIdAndNumber {
  /**  */
  id?: string;

  /**  */
  AgreementNumber?: string;
}

export interface OBEsResult {
  /**  */
  obeId?: string;

  /**  */
  obeNumber?: string;

  /**  */
  obeStatus?: string;

  /**  */
  agreementNumber?: number;

  /**  */
  registrationNumber?: string;

  /**  */
  statusUpdatedOn?: string;

  /**  */
  exemptionCode?: string;

  /**  */
  agreementStatus?: string;
}

export interface SimpleInvoice {
  /**  */
  invoiceId?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  customerIdentificationNumber?: string;

  /**  */
  invoiceStatus?: string;

  /**  */
  totalPriceVatIncluded?: string;

  /**  */
  totalPriceVatExcluded?: string;

  /**  */
  currency?: string;

  /**  */
  invoiceDate?: string;

  /**  */
  dueDate?: string;

  /**  */
  accountId?: string;

  /**  */
  agreementNumber?: number;

  /**  */
  agreementName?: string;

  /**  */
  dunningBlocked?: boolean;
}

export interface GetOBEsResult {
  /**  */
  totalResults?: number;

  /**  */
  results?: OBEsResult[];
}

export interface GetObeDetailsResult {
  /**  */
  obeId?: string;

  /**  */
  obeNumber?: string;

  /**  */
  obeStatus?: string;

  /**  */
  obeBatteryStatus?: string;

  /**  */
  exemptionCode?: string;

  /**  */
  agreementNumber?: number;

  /** Agreement GUID */
  agreementId?: string;

  /**  */
  source?: EnumGetObeDetailsResultSource;

  /**  */
  associatedCustomerNumber?: string;

  /**  */
  registrationNumber?: string;

  /**  */
  nationality?: string;

  /**  */
  classification?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  chargeable?: boolean;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  color?: string;

  /**  */
  euClass?: string;

  /**  */
  fuelType?: string;

  /**  */
  modelYear?: Date;

  /**  */
  co2?: string;

  /**  */
  vehicleDocumentationId?: string;

  /**  */
  vehicleDocumentationFileName?: string;

  /**  */
  vehicleDocumentationMimeType?: string;

  /**  */
  vehicleDocumentationIsUploaded?: boolean;

  /**  */
  depositBalance?: string;

  /**  */
  depositBalanceCurrency?: string;

  /**  */
  remainingDepositBalance?: number;

  /**  */
  depositStatus?: EnumGetObeDetailsResultDepositStatus;
}

export interface OBEAgreement {
  /**  */
  id?: string;

  /**  */
  obeNumber?: string;

  /**  */
  obeStatus?: string;

  /**  */
  obeBatteryStatus?: string;

  /**  */
  exemptionCode?: string;

  /**  */
  agreementId?: string;

  /**  */
  vehicle?: Vehicle;

  /**  */
  vehicleId?: string;

  /**  */
  updatedOn?: string;

  /**  */
  createdOn?: string;
}

export interface Vehicle {
  /**  */
  id?: string;

  /**  */
  registrationNumber?: string;

  /**  */
  registrationCountryCode?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  updatedOn?: string;

  /**  */
  createdOn?: string;

  /**  */
  euClass?: string;

  /**  */
  fuelType?: string;

  /**  */
  modelYear?: Date;

  /**  */
  vehicleDocumentation?: VehicleDocumentationResult;
}

export interface CheckVehicleResult {
  /**  */
  registrationNumber?: string;

  /**  */
  registrationCountryCode?: string;
}

export interface GetOBEandVehicleResult {
  /**  */
  totalResults?: number;

  /**  */
  results?: OBEAgreement[];
}

export interface UpdateObeRequest {
  /**  */
  obeNumber?: string;

  /**  */
  obeStatus?: EnumUpdateObeRequestObeStatus;

  /**  */
  obeBatteryStatus?: string;

  /**  */
  exemptionCode?: EnumUpdateObeRequestExemptionCode;

  /**  */
  agreementNumber?: number;

  /**  */
  associatedCustomerNumber?: string;

  /**  */
  registrationNumber?: string;

  /**  */
  registrationCountryCode?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  class?: string;

  /**  */
  euClass?: string;

  /**  */
  fuelType?: string;

  /**  */
  modelYear?: Date;

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];
}

export interface BulkMoveOBERequest {
  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];

  /**  */
  validateOnly?: boolean;
}

export interface BulkMoveOBEResponse {
  /**  */
  hasErrors?: boolean;

  /**  */
  rowCount?: number;

  /**  */
  errorLines?: number[];
}

export interface DetailedInvoice {
  /**  */
  invoiceId?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  customerIdentificationNumber?: string;

  /**  */
  invoiceStatus?: string;

  /**  */
  totalPriceVatIncluded?: string;

  /**  */
  totalPriceVatExcluded?: string;

  /**  */
  actualAmountPaid?: number;

  /**  */
  currency?: string;

  /**  */
  invoiceDate?: string;

  /**  */
  dueDate?: string;

  /**  */
  originalDueDate?: string;

  /**  */
  maxDaysPostponedDueDate?: number;

  /**  */
  accountId?: string;

  /**  */
  customerId?: string;

  /**  */
  customerNumber?: string;

  /**  */
  billingName?: string;

  /**  */
  billingAddress?: string;

  /**  */
  billingCountryCode?: string;

  /**  */
  distributionChanel?: string;

  /**  */
  agreementNumber?: number;

  /**  */
  agreementName?: string;

  /**  */
  agreementId?: string;

  /**  */
  debtCollectionAgency?: string;

  /**  */
  debtCollectionCaseNumber?: string;

  /**  */
  debtCollectionReceivedDate?: Date;

  /**  */
  debtCollectionClosedDate?: Date;

  /**  */
  debtCollectionClosureCode?: string;

  /**  */
  archiveStatus?: EnumArchivedInvoiceStatus;

  /**  */
  invoiceLines?: InvoiceLine[];

  /**  */
  attachments?: Attachment[];

  /**  */
  sentToInternalDebtDate?: Date;

  /**  */
  receivedByInternalDebtDate?: Date;

  /**  */
  sentToExternalDebtDate?: Date;

  /**  */
  receivedByExternalDebtDate?: Date;
}

export interface InvoiceLine {
  /**  */
  productName?: string;

  /**  */
  friendlyName?: string;

  /**  */
  totalPriceVatIncluded?: string;

  /**  */
  totalPriceVatExcluded?: string;

  /**  */
  totalVat?: string;

  /**  */
  totalVatPercentage?: string;

  /**  */
  operationType?: EnumOperationType;

  /**  */
  unitPrice?: string;

  /**  */
  quantity?: string;

  /**  */
  id?: string;

  /**  */
  isCredited?: boolean;
}

export interface Attachment {
  /**  */
  id?: string;

  /**  */
  fileName?: string;
}

export interface GenericPaginationQuery {
  /**  */
  max?: number;

  /**  */
  offset?: number;
}

export interface GenericDateAndPaginationQuery {
  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;

  /**  */
  orderBy?: string;

  /**  */
  orderDescending?: boolean;

  /**  */
  searchParameter?: string;
}

export interface PassingsDateAndPaginationQuery {
  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;

  /**  */
  orderBy?: string;

  /**  */
  orderDescending?: boolean;

  /**  */
  obeNumber?: string;
}

export interface AgreementCustomerQuery {
  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;

  /**  */
  orderBy?: string;

  /**  */
  orderDescending?: boolean;

  /**  */
  wildcardSearchTerm?: string;

  /**  */
  excludeInactives?: boolean;
}

export interface InvoiceCustomerQuery {
  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;

  /**  */
  orderBy?: string;

  /**  */
  orderDescending?: boolean;

  /**  */
  agreementNumber?: string;
}

export interface GetPassingsByInvoiceIdSearchRequest {
  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;

  /**  */
  obeNumber?: string;

  /**  */
  regNumber?: string;
}

export interface GetInvoiceByInvoiceNumberRequest {
  /**  */
  excludeInvoiceLines?: boolean;
}

export interface GetUnpaidInvoicesSearchRequest {
  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;

  /**  */
  onlyIncludeOverdue?: boolean;

  /**  */
  orderBy?: string;

  /**  */
  orderDescending?: boolean;

  /**  */
  searchParameter?: string;
}

export interface ManualPaymentRequest {
  /**  */
  amount?: number;

  /**  */
  customerIdentificationNumber?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  description?: string;

  /**  */
  reason?: string;

  /**  */
  receivalDate?: string;

  /**  */
  bankReference?: string;

  /**  */
  attachmentID?: string;
}

export interface CustomerTransactions {
  /**  */
  results?: Transaction[];

  /**  */
  totalResults?: number;
}

export interface CustomerPassings {
  /**  */
  results: Passing[];

  /**  */
  totalResults: number;
}

export interface CustomerPassingsSimple {
  /**  */
  results: PassingSimple[];

  /**  */
  totalResults: number;
}

export interface Transaction {
  /**  */
  id?: string;

  /**  */
  type?: string;

  /**  */
  transactedOn?: string;

  /**  */
  description?: string;

  /**  */
  quantity?: number;

  /**  */
  totalPrice?: number;

  /**  */
  operationType?: EnumOperationType;

  /**  */
  vat?: number;

  /**  */
  vatPercentage?: number;

  /**  */
  currency?: string;

  /**  */
  productId?: string;

  /**  */
  productName?: string;

  /**  */
  invoiceable?: boolean;

  /**  */
  invoiced?: boolean;

  /**  */
  invoiceId?: string;

  /**  */
  accountId?: string;

  /**  */
  refunded?: boolean;

  /**  */
  refundedTransactionId?: string;

  /**  */
  markedAsLoss?: boolean;

  /**  */
  paymentType?: string;

  /**  */
  externalUuid?: string;

  /**  */
  externalFileId?: string;

  /**  */
  source?: string;

  /**  */
  agreementNumber?: string;

  /**  */
  customerIdentificationNumber?: string;

  /**  */
  remittanceBankAccountNumber?: string;
}

export interface Passing {
  /**  */
  id?: string;

  /**  */
  type?: string;

  /**  */
  transactedOn?: string;

  /**  */
  description?: string;

  /**  */
  quantity?: number;

  /**  */
  totalPrice?: number;

  /**  */
  operationType?: EnumOperationType;

  /**  */
  vat?: number;

  /**  */
  vatPercentage?: number;

  /**  */
  currency?: string;

  /**  */
  productId?: string;

  /**  */
  productName?: string;

  /**  */
  invoiceable?: boolean;

  /**  */
  invoiced?: boolean;

  /**  */
  invoiceId?: string;

  /**  */
  accountId?: string;

  /**  */
  refunded?: boolean;

  /**  */
  refundedTransactionId?: string;

  /**  */
  markedAsLoss?: boolean;

  /**  */
  paymentType?: string;

  /**  */
  externalUuid?: string;

  /**  */
  externalFileId?: string;

  /**  */
  source?: string;

  /**  */
  obeNumber?: string;

  /**  */
  tariffClassification?: string;

  /**  */
  typeOfTollLane?: string;

  /**  */
  licensePlateDetected?: string;

  /**  */
  fuelType?: string;

  /**  */
  autoPassEmissionClass?: string;

  /**  */
  tariffClassificationAutoPass?: string;

  /**  */
  utcTimeStamp?: string;

  /**  */
  exitTollStationName?: string;

  /**  */
  entryTollStationName?: string;

  /**  */
  vehicleRegistrationNumber?: string;

  /**  */
  agreementNumber?: string;

  /**  */
  capturedBy?: string;
}

export interface PassingSimple {
  /**  */
  id?: string;

  /**  */
  transactedOn?: string;

  /**  */
  exitTollStationName?: string;

  /**  */
  entryTollStationName?: string;

  /**  */
  vehicleRegistrationNumber?: string;

  /**  */
  totalPrice?: number;

  /**  */
  currency?: string;

  /**  */
  operatorName?: string;

  /**  */
  obeNumber?: string;

  /**  */
  vat?: number;

  /**  */
  utcTimeStamp?: string;

  /**  */
  operationType?: EnumOperationType;

  /**  */
  invoiced?: boolean;
}

export interface Case {
  /**  */
  id?: string;

  /**  */
  caseNumber?: number;

  /**  */
  customerRef?: string;

  /**  */
  customerNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  customerDateOfBirth?: string;

  /**  */
  userRef?: string;

  /**  */
  assigneeName?: string;

  /**  */
  note?: string;

  /**  */
  status?: EnumCaseStatus;

  /**  */
  type?: EnumCaseType;

  /**  */
  createdOn?: string;

  /**  */
  updatedOn?: string;

  /**  */
  auxiliaryRef?: string;
}

export interface SearchAgreementsRequest {
  /**  */
  agreementStatus?: string;

  /**  */
  customerRef?: string;

  /**  */
  accountRefs?: [];

  /**  */
  name?: string;

  /**  */
  number?: number;

  /**  */
  dateTo?: string;

  /**  */
  dateFrom?: string;

  /**  */
  max?: number;

  /**  */
  offset?: number;
}

export interface AgreementSearchResult {
  /**  */
  results?: Agreement[];

  /**  */
  totalResults?: number;
}

export interface CaseSearchRequest {
  /**  */
  caseType?: EnumCaseType;

  /**  */
  userRef?: string;

  /**  */
  customerRef?: string;

  /**  */
  customerInfoSubstring?: string;

  /**  */
  caseNumber?: number;

  /**  */
  caseNumberSubstring?: number;

  /**  */
  status?: EnumCaseSearchRequestStatus;

  /**  */
  max?: number;

  /**  */
  offset?: number;

  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;
}

export interface CaseSearchResult {
  /**  */
  results?: Case[];

  /**  */
  totalResults?: number;
}

export interface CreateCaseRequest {
  /**  */
  customerRef?: string;

  /**  */
  userRef?: string;

  /**  */
  note?: string;

  /**  */
  type?: EnumCaseType;
}

export interface CreateInternationalCaseRequest {
  /**  */
  customerRef?: string;

  /**  */
  userRef?: string;

  /**  */
  note?: string;

  /**  */
  type?: EnumCaseType;

  /**  */
  attachment?: number[];

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;
}

export interface UpdateCaseRequest {
  /**  */
  customerRef?: string;

  /**  */
  userRef?: string;

  /**  */
  note?: string;

  /**  */
  type?: EnumCaseType;

  /**  */
  status?: EnumUpdateCaseRequestStatus;
}

export interface CreateCaseResult {
  /**  */
  id?: string;

  /**  */
  caseNumber?: number;
}

export interface CaseWithCommentsResult {
  /**  */
  id?: string;

  /**  */
  caseNumber?: number;

  /**  */
  customerRef?: string;

  /**  */
  userRef?: string;

  /**  */
  assigneeName?: string;

  /**  */
  note?: string;

  /**  */
  status?: EnumCaseWithCommentsResultStatus;

  /**  */
  type?: EnumCaseType;

  /**  */
  createdOn?: string;

  /**  */
  updatedOn?: string;

  /**  */
  caseComments?: CaseCommentResult[];

  /**  */
  auxiliaryRef?: string;
}

export interface CaseCommentResult {
  /**  */
  case?: CaseWithCommentsResult;

  /**  */
  id?: string;

  /**  */
  authorRef?: string;

  /**  */
  authorName?: string;

  /**  */
  content?: string;

  /**  */
  createdOn?: string;

  /**  */
  updatedOn?: string;

  /**  */
  attachment?: CaseCommentAttachmentResult;
}

export interface InvoiceHotelSearchResult {
  /**  */
  results?: InvoiceHotelResult[];

  /**  */
  totalResults?: number;
}

export interface InvoiceHotelResult {
  /**  */
  customerNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  internalFileId?: string;

  /**  */
  archiveKey?: string;

  /**  */
  issuerBic?: string;

  /**  */
  issuerIban?: string;

  /**  */
  supplierName?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  issuedDate?: string;

  /**  */
  dueDate?: string;

  /**  */
  amount?: string;

  /**  */
  invoiceType?: string;

  /**  */
  pdfUrl?: string;
}

export interface InvoiceHotelSearchQuery {
  /**  */
  accountNumber?: string;

  /**  */
  customerNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  issuedDateFrom?: string;

  /**  */
  issuedDateTo?: string;

  /**  */
  dueDateFrom?: string;

  /**  */
  dueDateTo?: string;

  /**  */
  amount?: string;

  /**  */
  invoiceType?: string;

  /**  */
  resultLimit?: string;

  /**  */
  page?: string;

  /**  */
  sort?: string;
}

export interface CaseCommentSearchResult {
  /**  */
  results?: CaseCommentResult[];

  /**  */
  totalResults?: number;
}

export interface CreateCaseCommentRequest {
  /**  */
  authorRef?: string;

  /**  */
  authorName?: string;

  /**  */
  content?: string;

  /**  */
  attachmentRef?: string;
}

export interface GetVehicleInformationRequest {
  /**  */
  licensePlateNumber?: string;

  /**  */
  country?: string;

  /**  */
  firstName?: string;

  /**  */
  companyNumber?: string;

  /**  */
  lastName?: string;

  /**  */
  userId?: string;
}

export interface GetVehicleInformationResponse {
  /**  */
  isOwner?: boolean;

  /**  */
  carMake?: string;

  /**  */
  carModel?: string;

  /**  */
  firstRegistrationDate?: Date;

  /**  */
  color?: string;

  /**  */
  carPlate?: string;

  /**  */
  technicalCode?: string;

  /**  */
  fuelType?: string;

  /**  */
  fuelTypeCode?: string;

  /**  */
  euCode?: string;

  /**  */
  co2?: string;

  /**  */
  weight?: string;

  /**  */
  length?: string;

  /**  */
  classification?: string;

  /**  */
  hybridCategory?: string;
}

export interface ApproveRejectAgreementsRequest {
  /**  */
  agreementIds?: string[];
}

export interface RegisterVehicleRequest {
  /**  */
  registrationNumber?: string;

  /**  */
  nationality?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  classification?: string;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  agreementId?: string;

  /**  */
  validFrom?: string;

  /**  */
  validTo?: string;

  /**  */
  euClass?: string;

  /**  */
  co2?: string;

  /**  */
  fuelType?: string;

  /**  */
  modelYear?: Date;

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];
}

export interface RegisterVehicleWithObeRequest {
  /**  */
  registrationNumber?: string;

  /**  */
  nationality?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  classification?: string;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  agreementId?: string;

  /**  */
  validFrom?: string;

  /**  */
  validTo?: string;

  /** GUID for the OBE that will be moved to this vehicle */
  obeId?: string;

  /**  */
  euClass?: string;

  /**  */
  co2?: string;

  /**  */
  fuelType?: string;

  /**  */
  modelYear?: Date;

  /**  */
  userRef?: string;

  /**  */
  customerRef?: string;

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];
}

export interface RegisterVehicleCreateCaseRequest {
  /**  */
  registrationNumber?: string;

  /**  */
  nationality?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  classification?: string;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  euClass?: string;

  /**  */
  fuelType?: string;

  /**  */
  modelYear?: Date;

  /**  */
  agreementId?: string;

  /**  */
  validFrom?: string;

  /**  */
  validTo?: string;

  /** GUID for the customer */
  customerRef?: string;

  /** GUID for the user on the TSP portal */
  userRef?: string;

  /** The reason for registering a new vehicle */
  note?: string;

  /**  */
  quantity?: number;

  /**  */
  deliveryAddress?: Address;

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];
}

export interface UnregisterVehicleRequest {
  /**  */
  registrationNumber?: string;

  /**  */
  registrationCountryCode?: string;

  /**  */
  reuseOBE?: boolean;

  /**  */
  obeNumber?: string;
}

export interface UnregisterVehiclesRequest {
  /**  */
  vehicles?: UnregisterVehicleRequest[];
}

export interface MoveObeRequest {
  /** GUID for the OBE to move */
  obeId?: string;

  /** GUID for the vehicle you want to move OBE to */
  targetVehicleId?: string;

  /** GUID for the agreement you want to move OBE to */
  targetAgreementId?: string;

  /** The name for the new agreement (if move OBE to new agreement) */
  agreementName?: string;

  /** GUID for which customer the new agreement will be made for (if move OBE to new agreement) */
  customerId?: string;
}

export interface OrganizationSearch {
  /** Letters that matches a part of an organization name */
  organizationName?: string;

  /** A nine digit long organization number */
  organizationNumber?: string;
}

export interface OrganizationInformation {
  /**  */
  organizationName?: string;

  /**  */
  organizationNumber?: string;

  /**  */
  organizationType?: string;

  /**  */
  locationAddress?: string;

  /**  */
  locationPostalCode?: string;

  /**  */
  locationCity?: string;

  /**  */
  locationCountry?: string;
}

export interface OrganizationSearchResult {
  /**  */
  organizationInformationItems?: OrganizationInformation[];
}

export interface Order {
  /**  */
  id?: string;

  /**  */
  customerRef?: string;

  /**  */
  customerName?: string;

  /**  */
  userRef?: string;

  /**  */
  userName?: string;

  /**  */
  orderedByName?: string;

  /**  */
  orderedById?: string;

  /**  */
  createdOn?: string;

  /**  */
  completedOn?: string;

  /**  */
  shippingAddress?: OrderShippingAddress;

  /**  */
  quantity?: number;

  /**  */
  content?: OrderItem[];

  /**  */
  status?: EnumOrderStatus;

  /**  */
  type?: EnumOrderType;

  /**  */
  setShippingAddressAsStandard?: boolean;

  /**  */
  agreementId?: string;

  /**  */
  agreementName?: string;

  /**  */
  vehicleRegistrationNumber?: string;

  /**  */
  vehicle?: OrderVehicle;

  /**  */
  registrationNumber?: string;

  /**  */
  registrationCountryCode?: string;
}

export interface OrderShippingAddress {
  /**  */
  id?: string;

  /**  */
  careOfName?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  postalCode?: string;

  /**  */
  postalDistrict?: string;

  /**  */
  countryCode?: string;
}

export interface OrderItem {
  /**  */
  itemNumber?: string;
}

export interface OrderVehicle {
  /**  */
  registrationNumber?: string;

  /**  */
  registrationCountryCode?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  modelYear?: string;

  /**  */
  size?: EnumVehicleSize;

  /**  */
  chargeable?: boolean;

  /**  */
  class?: string;

  /**  */
  euClass?: string;

  /**  */
  fuelType?: string;
}

export interface CreateOrderRequest {
  /**  */
  customerRef?: string;

  /**  */
  orderedByName?: string;

  /**  */
  orderedById?: string;

  /**  */
  quantity?: number;

  /**  */
  shippingAddress?: OrderShippingAddress;

  /**  */
  agreementId?: string;

  /**  */
  vehicleId?: string;

  /**  */
  vehicleRegistrationNumber?: string;

  /**  */
  vehicleRegistrationCountryCode?: string;

  /**  */
  type?: EnumCreateOrderRequestType;

  /**  */
  setShippingAddressAsStandard?: boolean;
}

export interface OrderSearchRequest {
  /**  */
  orderStatuses?: EnumOrderStatus[];

  /**  */
  max?: number;

  /**  */
  offset?: number;
}

export interface OrderSearchResult {
  /**  */
  results?: Order[];

  /**  */
  totalResults?: number;
}

export interface OrderCompleteRequest {
  /**  */
  content?: OrderItem[];
}

export interface CheckIfPersonExistsRequest {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /** In the form DDMMYY */
  birthDate?: string;
}

export interface UpdateRemittanceStatusRequest {
  /**  */
  caseId?: string;

  /**  */
  authorRef?: string;

  /**  */
  authorName?: string;
}

export interface CancelAgreementsRequest {
  /**  */
  agreementId?: string;

  /**  */
  reason?: string;

  /**  */
  relatedCaseNumber?: number;
}

export interface RegisterVehiclesAndObesRequest {
  /**  */
  vehiclesAndObes?: RegisterVehicleAndObeRequest[];
}

export interface RegisterVehicleAndObeRequest {
  /**  */
  obeNumber?: string;

  /** If present the OBE will be registered on this agreement */
  agreementId?: string;

  /** If present the endpoint will create a new agreement with this name on the provided customerId and register the provided OBE number when the agreement has been approved. */
  agreementName?: string;

  /**  */
  customerId?: string;

  /**  */
  vehicle?: RegisterVehicleRequest;
}

export interface ClaimOBEDepositRequest {
  /**  */
  orderNewOBE?: boolean;

  /**  */
  reason?: string;

  /**  */
  relatedCaseNumber?: string;

  /**  */
  obeNumber?: string;

  /**  */
  userRef?: string;
}

export interface RemittanceTransaction {
  /**  */
  transactionId?: string;

  /**  */
  invoiceNumber?: number;

  /**  */
  actualAmountPaid?: number;

  /**  */
  totalPriceVatIncluded?: number;

  /**  */
  description?: string;

  /**  */
  totalPrice?: number;

  /**  */
  currency?: string;

  /**  */
  remittanceBankAccountNumber?: string;

  /**  */
  remittanceStatus?: EnumRemittanceTransactionRemittanceStatus;
}

export interface ReplaceObeRequest {
  /**  */
  obeNumber: string;

  /**  */
  claimDeposit?: boolean;

  /**  */
  userRef: string;

  /**  */
  note?: string;
}

export interface ReturnOBERequest {
  /**  */
  obeNumbers: string[];
}

export interface OBEReturnStatus {
  /**  */
  obeNumber?: string;

  /**  */
  success?: boolean;
}

export interface ReturnOBEResponse {
  /**  */
  results?: OBEReturnStatus[];

  /**  */
  hasErrors?: boolean;
}

export interface User {
  /**  */
  id?: string;

  /**  */
  authId?: string;

  /**  */
  displayName?: string;

  /**  */
  customerRef?: string;

  /**  */
  roles?: string[];
}

export interface RegisterUserRequest {
  /**  */
  displayName?: string;

  /**  */
  customerRef?: string;
}

export interface RestoreAccessRequest {
  /**  */
  customerNumber?: string;

  /**  */
  vehicleRegistrationNumber?: string;
}

export interface CreateCaseCommentAttachmentRequest {
  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];
}

export interface CaseCommentAttachmentResult {
  /**  */
  id?: string;

  /**  */
  fileName?: string;

  /**  */
  isUploaded?: boolean;

  /**  */
  caseComment?: CaseCommentResult;
}

export interface CompleteUnknownPaymentRequest {
  /**  */
  customerIdentificationNumber?: string;
}

export interface CreditInvoiceLineRequest {
  /**  */
  invoiceNumber?: string;

  /**  */
  invoiceLineIds?: string[];

  /**  */
  reason?: string;

  /**  */
  relatedCaseNumber?: number;
}

export interface ApproveRejectCreditRequest {
  /**  */
  caseId?: string;

  /**  */
  approved?: boolean;

  /**  */
  authorName?: string;

  /**  */
  authorRef?: string;
}

export interface ActivatePrivilegeRequest {
  /**  */
  customerNumber?: string;

  /**  */
  tokenValue: string;
}

export interface PrivilegeGrantTokenModel {
  /**  */
  id?: string;

  /**  */
  createdOn?: string;

  /**  */
  receiverEmail?: string;

  /**  */
  customerRef?: string;

  /**  */
  privilegeType?: EnumPrivilegeGrantTokenModelPrivilegeType;

  /**  */
  validUntil?: string;
}

export interface Privilege {
  /**  */
  id?: string;

  /**  */
  validFrom?: string;

  /**  */
  validTo?: string;

  /**  */
  userId?: string;

  /**  */
  customerRef?: string;

  /**  */
  receiverEmail?: string;

  /**  */
  privilegeType?: EnumPrivilegePrivilegeType;

  /**  */
  userName?: string;
}

export interface GrantPrivilegeRequest {
  /** GUID for the customer one are granting access to */
  customerRef?: string;

  /**  */
  privilegeType?: EnumGrantPrivilegeRequestPrivilegeType;

  /**  */
  receiverEmail?: string;

  /**  */
  reason?: string;
}

export interface RevokePrivilegeRequest {
  /**  */
  reason?: string;
}

export interface SearchPrivilegeRequest {
  /**  */
  email?: string;

  /**  */
  max?: number;

  /**  */
  offset?: number;
}

export interface SearchPrivilegesResult {
  /**  */
  results?: Privilege[];

  /**  */
  totalResults?: number;
}

export interface SearchPendingPrivilegesResult {
  /**  */
  results?: PrivilegeGrantTokenModel[];

  /**  */
  totalResults?: number;
}

export interface CreateVehicleDocumentationRequest {
  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];
}

export interface VehicleDocumentationResult {
  /**  */
  id?: string;

  /**  */
  fileName?: string;

  /**  */
  isUploaded?: boolean;

  /**  */
  vehicleId?: string;

  /**  */
  vehicle?: Vehicle;
}

export interface PrivilegesExistsResult {
  /**  */
  privilegesExists?: boolean;
}

export interface PostponeInvoiceDueDateRequest {
  /**  */
  invoiceNumber?: string;

  /**  */
  dueDate?: Date;

  /**  */
  reason?: string;
}

export interface PostponeInvoiceDueDateResponse {
  /**  */
  customerId?: string;

  /**  */
  invoiceId?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  errorMessage?: string;

  /**  */
  didUpdateDueDate?: boolean;
}

export interface CaseLookupResult {
  /**  */
  id?: string;

  /**  */
  caseNumber?: number;

  /**  */
  status?: string;
}

export interface UploadInventoryEntriesRequest {
  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;

  /**  */
  attachment?: number[];

  /**  */
  validateOnly?: boolean;
}

export interface UploadInventoryEntriesResponse {
  /**  */
  hasErrors?: boolean;

  /**  */
  rowCount?: number;

  /**  */
  errorLines?: number[];
}

export interface PassingRequest {
  /**  */
  hasErrors?: boolean;

  /**  */
  rowCount?: number;

  /**  */
  errorLines?: number[];
}

export interface VehicleNote {
  /**  */
  OBEId?: string;

  /**  */
  OBENumber?: string;

  /**  */
  agreementnumber?: string;

  /**  */
  RegistrationNumber?: string;

  /**  */
  RegistrationCountryCode?: string;

  /**  */
  Class?: string;

  /**  */
  FuelType?: string;

  /**  */
  EUClass?: string;

  /**  */
  Size?: EnumVehicleSize;

  /**  */
  Brand?: string;

  /**  */
  Model?: string;

  /**  */
  ModelYear?: string;

  /**  */
  Co2?: string;

  /**  */
  Color?: string;
}
export enum EnumCustomerCreateDataCustomerType {
  'Person' = 'Person',
  'Company' = 'Company'
}
export enum EnumAgreementStatus {
  'New' = 'New',
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'Frozen' = 'Frozen',
  'AwaitingApproval' = 'AwaitingApproval',
  'ApprovedAwaitingShipping' = 'ApprovedAwaitingShipping',
  'Rejected' = 'Rejected',
  'Cancelled' = 'Cancelled'
}
export enum EnumSimpleAgreementStatus {
  'New' = 'New',
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'Frozen' = 'Frozen',
  'AwaitingApproval' = 'AwaitingApproval',
  'ApprovedAwaitingShipping' = 'ApprovedAwaitingShipping',
  'Rejected' = 'Rejected',
  'Cancelled' = 'Cancelled'
}
export enum EnumAgreementWithOBEsAndVehiclesStatus {
  'New' = 'New',
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'Frozen' = 'Frozen',
  'AwaitingApproval' = 'AwaitingApproval',
  'ApprovedAwaitingShipping' = 'ApprovedAwaitingShipping',
  'Rejected' = 'Rejected',
  'Cancelled' = 'Cancelled'
}
export enum EnumCustomerCustomerStatus {
  'Active' = 'Active',
  'Frozen' = 'Frozen',
  'Inactive' = 'Inactive'
}
export enum EnumCustomerCustomerType {
  'Person' = 'Person',
  'Company' = 'Company'
}
export enum EnumCreateOrUpdateBankReferenceStatus {
  'Accepted' = 'Accepted',
  'Rejected' = 'Rejected',
  'Canceled' = 'Canceled'
}
export enum EnumGetObeDetailsResultSource {
  'Unknown' = 'Unknown',
  'TSP_Portal' = 'TSP_Portal',
  'B2B_API' = 'B2B_API',
  'Self_Service' = 'Self_Service'
}
export enum EnumGetObeDetailsResultDepositStatus {
  'Requested' = 'Requested',
  'Paid' = 'Paid',
  'Returned' = 'Returned',
  'RecordedAsRevenue' = 'RecordedAsRevenue'
}
export enum EnumUpdateObeRequestObeStatus {
  'New' = 'New',
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'Shipped' = 'Shipped',
  'Frozen' = 'Frozen',
  'Lost' = 'Lost',
  'Terminated' = 'Terminated'
}
export enum EnumUpdateObeRequestExemptionCode {
  'NoExemption' = 'NoExemption',
  'Handicap' = 'Handicap',
  'BusInRoute' = 'BusInRoute',
  'Maxitaxi' = 'Maxitaxi'
}
export enum EnumCaseStatus {
  'New' = 'New',
  'InProgress' = 'InProgress',
  'Paused' = 'Paused',
  'Closed' = 'Closed'
}
export enum EnumCaseSearchRequestStatus {
  'New' = 'New',
  'InProgress' = 'InProgress',
  'Paused' = 'Paused',
  'Closed' = 'Closed'
}
export enum EnumUpdateCaseRequestStatus {
  'New' = 'New',
  'InProgress' = 'InProgress',
  'Paused' = 'Paused',
  'Closed' = 'Closed'
}
export enum EnumCaseWithCommentsResultStatus {
  'New' = 'New',
  'InProgress' = 'InProgress',
  'Paused' = 'Paused',
  'Closed' = 'Closed'
}
export enum EnumOrderType {
  'Obe' = 'Obe',
  'ObeHolder' = 'ObeHolder',
  'BlankObe' = 'BlankObe',
  'ObeBox' = 'ObeBox'
}
export enum EnumOrderStatus {
  'Pending' = 'Pending',
  'InProgress' = 'InProgress',
  'Shipped' = 'Shipped',
  'Returned' = 'Returned'
}
export enum EnumCreateOrderRequestType {
  'Obe' = 'Obe',
  'ObeHolder' = 'ObeHolder',
  'BlankObe' = 'BlankObe',
  'ObeBox' = 'ObeBox'
}
export enum EnumRemittanceTransactionRemittanceStatus {
  'Resolved' = 'Resolved',
  'Unresolved' = 'Unresolved'
}
export enum EnumPrivilegeGrantTokenModelPrivilegeType {
  'FULL_ACCESS' = 'FULL_ACCESS'
}
export enum EnumPrivilegePrivilegeType {
  'FULL_ACCESS' = 'FULL_ACCESS'
}
export enum EnumGrantPrivilegeRequestPrivilegeType {
  'FULL_ACCESS' = 'FULL_ACCESS'
}
export enum EnumOperationType {
  'Credit' = 'Credit',
  'Debit' = 'Debit'
}

export enum EnumCaseType {
  'CustomerInquiry' = 'CustomerInquiry',
  'InvalidTransactionAuthor' = 'InvalidTransactionAuthor',
  'NewCustomer' = 'NewCustomer',
  'NewAgreement' = 'NewAgreement',
  'NewVehicle' = 'NewVehicle',
  'VehicleChange' = 'VehicleChange',
  'TerminateAgreement' = 'TerminateAgreement',
  'DebtCollectionFailure' = 'DebtCollectionFailure',
  'ObeReturned' = 'ObeReturned',
  'ObeReplaced' = 'ObeReplaced',
  'ObeStopped' = 'ObeStopped',
  'InvoiceDispute' = 'InvoiceDispute',
  'RemittanceRequest' = 'RemittanceRequest',
  'PaymentLost' = 'PaymentLost',
  'AutomatedRemittance' = 'AutomatedRemittance',
  'ManualRemittance' = 'ManualRemittance',
  'CancelAgreement' = 'CancelAgreement',
  'ClaimDeposit' = 'ClaimDeposit',
  'CreditNote' = 'CreditNote',
  'GrantSelfServiceAccess' = 'GrantSelfServiceAccess',
  'RevokeSelfServiceAccess' = 'RevokeSelfServiceAccess',
  'PostponeInvoiceDueDate' = 'PostponeInvoiceDueDate',
  'SelfServiceEditVehicle' = 'SelfServiceEditVehicle',
  'SelfServiceEditInternationalVehicle' = 'SelfServiceEditInternationalVehicle',
  'AddressChange' = 'AddressChange',
  'DebtCollectionClosure' = 'DebtCollectionClosure',
  'DebtCollectionFiles' = 'DebtCollectionFiles',
  'UpdateCustomer' = 'UpdateCustomer'
}

export enum EnumInvoiceType {
  'DebtCollectionNotice' = 'DebtCollectionNotice',
  'Invoice' = 'Invoice',
  'CreditNoteDistributed' = 'CreditNoteDistributed'
}

export enum EnumArchivedInvoiceStatus {
  'NotStarted' = 'NotStarted',
  'Offboarded' = 'Offboarded',
  'Verified' = 'Verified',
  'Archived' = 'Archived',
  'FailedToUpload' = 'FailedToUpload',
  'FailedToVerify' = 'FailedToVerify',
  'FailedToDelete' = 'FailedToDelete',
  'NotEligible' = 'NotEligible'
}

export enum EnumVehicleSize {
  'Heavy' = 'Heavy',
  'Regular' = 'Regular'
}

export enum EnumSelectedInvoiceFilter {
  'Unpaid' = 'Unpaid',
  'Paid' = 'Paid',
  'Remittance' = 'Remittance'
}
