export const CUSTOMERS = '/';
export const CUSTOMERS_RECOVER = '/recover';
export const PROFILE = '/profile';

export const BANK_REFERENCES = '/bank-references';
export const HOME = '/home';
export const INVOICES = '/invoices';
export const PASSINGS = '/passings';
export const CASES = '/cases';
export const CREATE_AGREEMENT = '/agreement/create';
export const AGREEMENTS = '/agreement';
export const TRANSFER_TAG = '/tag/transfer/';
export const ORDER_TAG = '/order/tag';
export const ORDER_EMPTY_TAG = '/order/empty-tag';
export const REMOVE_VEHICLE = '/tag/unregister/';
export const REGISTER_BLANK_TAG = '/tag/register/';
export const LEGACY_INVOICES = '/invoices/legacy';
export const TRANSFER_TAG_BULK = '/tag/transfer/bulk';
export const TRANSFER_TAG_BULK_DOCS = '/tag/transfer/bulk/docs';

export const TRANSFER_TAG_BY_ID = (tagId: string) => `/tag/transfer/${tagId}`;
export const INVOICE = (invoiceNumber: string) => `/invoices/${invoiceNumber}`;
export const PASSING_DETAILS = (passingId: string) => `/passings/${passingId}`;
export const TAG_DETAILS = (obeNumber: string) => `/vehicle/${obeNumber}`;
export const EDIT_VEHICLE = (obeNumber: string) => `/vehicle/edit/${obeNumber}`;
export const REMOVE_VEHICLE_BY_ID = (tagId: string) =>
  `/tag/unregister/${tagId}`;
export const PRIVILEGE_GRANT_TOKEN = (token: string) =>
  `/privilegeGrantToken/${token}`;
export const BANK_REFERENCE = (
  referenceId: string,
  redirectUrl: string,
  shortname: string
) =>
  `/bank-reference/${referenceId}&redirectUrl=${redirectUrl}&shortname=${shortname}`;
