import { Box, Typography } from '@material-ui/core';
import { NavLinkButton } from 'components/Button';
import ExternalNavLinkButton from 'components/Button/ExternalNavLinkButton';
import Icon from 'components/Icons';
import { Title } from 'components/Text';
import { AUTOSYNC_LOGO } from 'constants/icons';
import {
  AGREEMENTS,
  CUSTOMERS,
  CUSTOMERS_RECOVER,
  HOME,
  INVOICES,
  PASSINGS
} from 'constants/routes';
import { EXTERNAL_HELP_PAGE } from 'constants/urls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CurrentCustomer from 'store/customer/useCurrentCustomer';
import '../Navigation.scss';
import useStyles from '../NavigationStyles';
import NavigationProfileMenu from '../ProfileMenu/NavigationProfileMenu';

interface NavigationNavbarInterface {
  isNavLinksEnabled?: boolean;
}

export default function NavigationNavbar({
  isNavLinksEnabled = true
}: NavigationNavbarInterface): JSX.Element {
  const {
    button,
    navigationProfile,
    navigationProfileBox,
    logo,
    logoBanner,
    navLinks,
    navigationBox,
    profileInfoStyles
  } = useStyles();
  const { push } = useHistory();
  const { t } = useTranslation();
  const onCustomersPage = useRouteMatch(CUSTOMERS);
  const onCustomersRecoverPage = useRouteMatch(CUSTOMERS_RECOVER);
  const { customer, isBusinessCustomer } = CurrentCustomer.useContainer();

  const name = isBusinessCustomer
    ? customer?.organizationName || ''
    : `${customer?.personFirstName || ''} ${customer?.personLastName || ''}`;

  const customerNumber = customer?.customerNumber;

  return (
    <Box className={navigationBox}>
      <div
        className={logoBanner}
        onClick={() => push(CUSTOMERS)}
        onKeyPress={() => push(CUSTOMERS)}
        role="link"
        tabIndex={0}
      >
        <Box className={logo}>
          <Icon name={AUTOSYNC_LOGO} height="55px" width="289px" />
        </Box>
      </div>

      {!onCustomersPage?.isExact &&
      !onCustomersRecoverPage?.isExact &&
      isNavLinksEnabled ? (
        <>
          <Box className={navLinks}>
            <NavLinkButton
              path={HOME}
              className={`${button} jkl-body`}
              label={t('titles.home')}
              ariaLabel="link to home"
            />

            <NavLinkButton
              path={AGREEMENTS}
              className={`${button} jkl-body`}
              label={t('titles.agreements')}
              ariaLabel="link to agreements"
            />

            <NavLinkButton
              path={PASSINGS}
              className={`${button} jkl-body`}
              label={t('titles.passings')}
              ariaLabel="navlink-passings"
            />

            <NavLinkButton
              path={INVOICES}
              className={`${button} jkl-body`}
              label={t('titles.invoices')}
              ariaLabel="navlink-invoices"
            />

            <ExternalNavLinkButton
              path={EXTERNAL_HELP_PAGE}
              className={`${button} jkl-body`}
              label={t('titles.help')}
              ariaLabel="link to help page"
            />
          </Box>
          <Box className={navigationProfileBox}>
            <Box className={navigationProfile}>
              <Title className={profileInfoStyles} align="right">
                {name || ''}
              </Title>
              <Typography variant='subtitle2'>
                {`${t('customers.customer_number')}: ${customerNumber || ''}`}
              </Typography>
            </Box>
            <NavigationProfileMenu />
          </Box>
        </>
      ) : (
        <Box>
          <NavigationProfileMenu />
        </Box>
      )}
    </Box>
  );
}
