/* eslint-disable no-fallthrough */
import { useState } from 'react';
import { GetObeDetailsResult } from 'api';
import { createContainer } from 'unstated-next';

import useGetObeDetails from 'hooks/vehicle/useGetObeDetails';
import tagOptions from 'constants/tagOptions';
import IVehiclesAndTagOption from 'common/interfaces/IVehicleAndTagOption';
import UnregisterVehicleSteps from 'modules/UnregisterVehicle/UnregisterVehicleSteps';

function useUnregisterVehicle() {
  const [getObe] = useGetObeDetails();
  const [vehicle, setVehicle] = useState<IVehiclesAndTagOption | null>(null);
  const [vehicles, setVehicles] = useState<IVehiclesAndTagOption[]>([]);

  const [confirmCorrectInfo, setConfirmCorrectInfo] = useState<boolean>(false);
  const [isTagOptionEditable, setIsTagOptionEditable] = useState<boolean>(true);
  const [disableRegisterNew, setDisableRegisterNew] = useState<boolean>(false);

  const [pageStep, setPageStep] = useState<UnregisterVehicleSteps>(
    UnregisterVehicleSteps.VehicleStep
  );

  const getTagById = async (id: string) => {
    const obeResult: GetObeDetailsResult | null = await getObe(id);
    if (obeResult) {
      const formattedObeResult: IVehiclesAndTagOption = {
        ...obeResult,
        tagOption: tagOptions.KEEP_AND_REUSE,
        isEditMode: true
      };
      setVehicle(formattedObeResult);
    } else setVehicle(null);
  };

  const resetAll = () => {
    setVehicle(null);
    setVehicles([]);
    setConfirmCorrectInfo(false);
    setIsTagOptionEditable(true);
    setDisableRegisterNew(false);
  };

  return {
    pageStep,
    setPageStep,
    vehicle,
    setVehicle,
    getTagById,
    confirmCorrectInfo,
    setConfirmCorrectInfo,
    isTagOptionEditable,
    setIsTagOptionEditable,
    vehicles,
    setVehicles,
    disableRegisterNew,
    setDisableRegisterNew,
    resetAll
  };
}

export default createContainer(useUnregisterVehicle);
