import React from 'react';
import { Divider } from '@material-ui/core';
import useStyles from './DividerStyles';

interface IDivider {
  hasDefaultSpacing?: boolean;
  className?: string;
}

export default function DividerComponent({
  hasDefaultSpacing,
  className
}: IDivider) {
  const { divider } = useStyles();

  return (
    <Divider
      className={hasDefaultSpacing ? ` ${divider} ${className}` : className}
    />
  );
}

DividerComponent.defaultProps = {
  hasDefaultSpacing: true,
  className: ''
};
