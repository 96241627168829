import { ObeService, GetObeDetailsResult } from 'api';

export default function useGetObeDetails() {
  const getObe = async (obeNumber: string) => {
    if (!obeNumber) return null;
    try {
      const res: GetObeDetailsResult = await ObeService.getObeDetails({
        obeNumber
      });

      if (res) {
        return res;
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return null;
  };
  return [getObe];
}
