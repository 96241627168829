import IAttachmentFile from 'common/interfaces/IFileAttachment';
import IVehicle from 'common/interfaces/IVehicle';
import IVehicleAndAgreement from 'common/interfaces/IVehicleAndAgreement';
import OrderTagSteps from 'modules/OrderTag/OrderTagSteps';
import { useEffect, useState } from 'react';
import CustomAddress from 'store/address/useAddress';
import SelectedAgreement from 'store/agreements/useSelectedAgreement';
import CurrentCustomer from 'store/customer/useCurrentCustomer';
import { createContainer } from 'unstated-next';

function useOrderTag() {
  const { agreementEditMode, agreement } = SelectedAgreement.useContainer();

  const [vehicle, setVehicle] = useState<IVehicle | null>(null);
  const [vehicles, setVehicles] = useState<IVehicleAndAgreement[]>([]);

  const [pageStep, setPageStep] = useState<OrderTagSteps>(
    OrderTagSteps.ChooseVehicle
  );

  const [confirmCorrectInfo, setConfirmCorrectInfo] = useState<boolean>(false);
  const [hasReadTerms, setHasReadTerms] = useState<boolean>(false);

  const [isForwardDeactivated, setIsForwardDeactivated] = useState<boolean>(
    true
  );

  const [fileAttachment, setFileAttachment] = useState<IAttachmentFile | null>(
    null
  );

  const { hasErrors, deliveryAddress } = CustomAddress.useContainer();

  const { isBusinessCustomer } = CurrentCustomer.useContainer();
  const hasConfirmedAndNotEditMode =
    pageStep === OrderTagSteps.ChooseVehicle && confirmCorrectInfo;

  const hasEditMode =
    vehicles.length &&
    vehicles.filter((vehicleObject) => {
      return vehicleObject.isEditMode && vehicleObject.isEditMode === true;
    }).length;

  useEffect(() => {
    if (
      hasConfirmedAndNotEditMode &&
      !isBusinessCustomer &&
      !agreementEditMode &&
      vehicle &&
      agreement
    ) {
      return setIsForwardDeactivated(false);
    }

    if (
      hasConfirmedAndNotEditMode &&
      vehicles.length &&
      !hasEditMode &&
      isBusinessCustomer
    ) {
      return setIsForwardDeactivated(false);
    }

    if (pageStep === OrderTagSteps.ChooseDeliveryAddress && deliveryAddress) {
      return setIsForwardDeactivated(false);
    }

    if (pageStep === OrderTagSteps.ProceedOrder && hasReadTerms) {
      return setIsForwardDeactivated(false);
    }

    return setIsForwardDeactivated(true);
  }, [
    pageStep,
    deliveryAddress,
    vehicle,
    vehicles,
    confirmCorrectInfo,
    hasReadTerms,
    isBusinessCustomer,
    agreementEditMode,
    agreement,
    hasConfirmedAndNotEditMode,
    hasEditMode,
    hasErrors
  ]);

  const resetAll = () => {
    setVehicle(null);
    setConfirmCorrectInfo(false);
    setVehicles([]);
  };

  const resetPartially = () => {
    setConfirmCorrectInfo(false);
  };

  return {
    vehicle,
    setVehicle,
    vehicles,
    setVehicles,
    pageStep,
    setPageStep,
    isForwardDeactivated,
    setIsForwardDeactivated,
    confirmCorrectInfo,
    setConfirmCorrectInfo,
    hasReadTerms,
    setHasReadTerms,
    resetAll,
    resetPartially,
    fileAttachment,
    setFileAttachment
  };
}

export default createContainer(useOrderTag);
