/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import ReactGA from 'react-ga4';
import { analytics } from '../env-config.json';
import App from './App';

if (module.hot) {
  module.hot.accept();
}

if (analytics?.enabled) {
  ReactGA.initialize(analytics.trackingId);
}

ReactDOM.render(<App />, document.getElementById('root'));
