import React from 'react';
import IIcon from 'common/interfaces/IIcon';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore next-line
import icon from 'url:../../../assets/Icons/obe_passing.svg';
import './Icons.scss'

export default function ObePassingIcon({
  height = '37',
  width = '48',
  style
}: IIcon) {
  return (
    <img
      width={width}
      height={height}
      src={icon}
      style={style}
      className={'passing-icon'}
    />
  );
}