import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import useStyles from './ButtonStyles';
import SecondaryButton from './SecondaryButton';

interface IDownload {
  ariaLabel: string;
  onClick: () => void;
  loading?: boolean;
  children?: string | JSX.Element;
  disabled?: boolean;
  arrow?: 'left' | 'right';
  compact?: boolean;
}

export default function Download({
  ariaLabel,
  onClick,
  children,
  loading,
  disabled,
  arrow,
  compact
}: IDownload) {
  const { t } = useTranslation();
  const { download, wrapper } = useStyles();

  return (
    <div className={wrapper}>
      <SecondaryButton
        ariaLabel={ariaLabel}
        className={download}
        onClick={onClick}
        disabled={loading || disabled}
        arrow={arrow}
        size={compact ? 'small' : 'large'}
      >
        <span>{children || t('buttons.download')}</span>
      </SecondaryButton>
      {loading && <Spinner isButtonProgress />}
    </div>
  );
}

Download.defaultProps = {
  disabled: false,
  loading: false,
  children: ''
};
