import React from 'react';
import { Button } from '@material-ui/core';

interface IInfoButton {
  className?: string;
  ariaLabel: string;
  children: string | JSX.Element;
  onClick: () => void;
}

export default function InfoButton({
  className,
  children,
  ariaLabel,
  onClick
}: IInfoButton) {
  return (
    <Button
      className={className}
      onClick={onClick}
      variant="outlined"
      aria-label={ariaLabel}
    >
      {children}
    </Button>
  );
}

InfoButton.defaultProps = {
  className: ''
};
