import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    root: { 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Full viewport height
      textAlign: 'center', // Center text if there's any
      overflow: 'hidden', // Prevent scrollbars if video is larger than the viewport
    }
  })
);
