import React from 'react';
import IButton from 'common/interfaces/IButton';
import Spinner from 'components/Spinner/Spinner';
import { PrimaryButton as JokulPrimaryButton } from '@fremtind/jkl-button-react';
import useStyles from './ButtonStyles';
import '@fremtind/jkl-button/button.min.css';
import '@fremtind/jkl-loader/loader.min.css';

export default function PrimaryButton({
  className,
  children,
  ariaLabel,
  disabled,
  loading,
  onClick,
  preventDefault,
  size
}: IButton) {
  const { primaryButton, wrapper } = useStyles();

  return (
    <div className={wrapper}>
      <JokulPrimaryButton
        type='button'
        color="primary"
        className={`${primaryButton} ${className}`}
        aria-labelledby={ariaLabel}
        disabled={loading || disabled}
        onClick={(e) => {
          if (preventDefault) {
            e.preventDefault();
          }
          onClick();
        }}
        forceCompact={size === 'small'}
      >
        {children}
      </JokulPrimaryButton>
      {loading && <Spinner isButtonProgress />}
    </div>
  );
}

PrimaryButton.defaultProps = {
  disabled: false,
  className: '',
  loading: false,
  preventDefault: false,
  size: undefined
};
