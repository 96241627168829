import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languagedetector from 'i18next-browser-languagedetector';
import Language from 'constants/language';

import en from '../locale/en.json';
import no from '../locale/no.json';

i18n
  .use(languagedetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { common: en },
      no: { common: no }
    },
    fallbackLng: Language.norwegian,
    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: ['common'],
    returnObjects: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    },
    load: 'languageOnly',
    supportedLngs: [Language.norwegian, Language.english]
  });

export default i18n;
