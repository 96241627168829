import { createContainer } from 'unstated-next';
import { Privilege } from 'api';
import { useState } from 'react';

function usePrivileges() {
  const [privileges, setPrivileges] = useState<Privilege[]>([]);

  const removePrivilege = (privilegeId: string) => {
    const newPrivileges = privileges.filter(
      (privilege) => privilege.id !== privilegeId
    );
    setPrivileges(newPrivileges);
  };

  return {
    privileges,
    setPrivileges,
    removePrivilege
  };
}

export default createContainer(usePrivileges);
