import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GRAY } from 'constants/colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    info: {
      display: 'flex',
      color: GRAY,
      margin: 0
    },
    header: {
      padding: theme.spacing(2, 0)
    }
  })
);
