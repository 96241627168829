import React from 'react';
import { Typography } from '@material-ui/core';
import IText from './IText';

export default function Title({ children, align, variant, className }: IText) {
  return (
    <Typography
      className={className}
      aria-label="title"
      align={align}
      variant={variant}
    >
      {children}
    </Typography>
  );
}

Title.defaultProps = {
  align: 'left',
  variant: 'h6'
};
