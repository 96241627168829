import { Container } from '@material-ui/core';
import ISwitchRouteProps from 'common/interfaces/ISwitchRouteProps';
import Footer from 'components/JokulFooter/Footer';
import Navigation from 'components/Navigation/Navigation';
import Spinner from 'components/Spinner/Spinner';
import {
  BANK_REFERENCES,
  CUSTOMERS,
  CUSTOMERS_RECOVER,
  PRIVILEGE_GRANT_TOKEN
} from 'constants/routes';
import React, { Suspense, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import Compose from 'utils/compose/compose';
import useStyles from '../style/routesStyling';
import '@fremtind/jkl-alert-message/alert-message.min.css';
import '@fremtind/jkl-core/core.min.css';

export default function SwitchRoute({
  path,
  name,
  provider: Provider,
  component: Component
}: ISwitchRouteProps) {
  const [{ userCookie }] = useCookies(['X-FJELLINJEN']);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const matchingGrantAccessToken = useRouteMatch(
    PRIVILEGE_GRANT_TOKEN(':token')
  );
  const exact = true;
  const isCustomerProfilesPage = path === CUSTOMERS;
  const sessionStorageBankReference = sessionStorage.getItem('bank-ref');

  const hasBankReferenceAndIsNavigatingToBankReferencePage = () =>
    pathname === BANK_REFERENCES && sessionStorageBankReference !== null;

  useEffect(() => {
    if (matchingGrantAccessToken !== null) return;
    if (pathname === CUSTOMERS_RECOVER) return;

    if (pathname !== CUSTOMERS && !userCookie) {
      push(CUSTOMERS);
      return;
    }

    if (hasBankReferenceAndIsNavigatingToBankReferencePage()) {
      push(sessionStorageBankReference as string);
      // eslint-disable-next-line no-useless-return
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCookie, pathname, push, matchingGrantAccessToken]);

  const { container, flexWrapper, flexGrow } = useStyles();

  const content: JSX.Element = (
    <Route
      key={name}
      path={path.toString()}
      exact={exact}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component={(props: any) => (
        <>
          <div className={flexWrapper}>
            <Navigation />
            <Suspense fallback={<Spinner />}>
              <Container className={container}>
                <Component {...props} />
              </Container>
            </Suspense>

            <div className={flexGrow} />

            <Footer hideLinks={isCustomerProfilesPage} />
          </div>
        </>
      )}
    />
  );

  if (!Provider) return content;

  // Check is a Provider array [Provider, Provider] is supplied
  if (Array.isArray(Provider)) {
    return <Compose providers={Provider}>{content}</Compose>;
  }

  return <Provider>{content}</Provider>;
}
