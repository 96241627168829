import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RED } from 'constants/colors';

export default makeStyles(() =>
  createStyles({
    variantError: {
      backgroundColor: `${RED}!important`
    }
  })
);
