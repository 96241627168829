import { Agreement } from 'api';
import IVehicleWithObeAndAgreement from 'common/interfaces/IVehicleWithObeAndAgreement';
import RegisterBlankTagSteps from 'modules/RegisterBlankTag/RegisterBlankTagSteps';
import { useEffect, useState } from 'react';
import SearchVehicleStore from 'store/vehicle/useSearchVehicle';
import { createContainer } from 'unstated-next';

const useRegisterBlankTag = () => {
  const {
    searchVehicle,
    setSearchVehicle,
    vehicleDocumentationAttachment
  } = SearchVehicleStore.useContainer();

  const [agreement, setAgreement] = useState<Agreement | string>('');
  const [tagNumber, setTagNumber] = useState<string>('');
  const [isInfoConfirmed, setIsInfoConfirmed] = useState<boolean>(false);
  const [isConfirmButtonAvailable, setIsConfirmButtonAvailable] = useState<
    boolean
  >(false);

  const [isForwardDeactivated, setIsForwardDeactivated] = useState<boolean>(
    true
  );

  const [confirmedVehicles, setConfirmedVehicles] = useState<
    IVehicleWithObeAndAgreement[]
  >([]);

  const [activeVehicle, setActiveVehicle] = useState<Partial<
    IVehicleWithObeAndAgreement
  > | null>(null);

  const [pageStep, setPageStep] = useState<RegisterBlankTagSteps>(
    RegisterBlankTagSteps.ChooseVehicleAndTag
  );

  const handleClickForward = () => {
    if (!activeVehicle) return;

    const incomingVehicle: IVehicleWithObeAndAgreement = {
      ...activeVehicle,
      obeNumber: tagNumber,
      agreement,
      isEditable: false,
      attachment: vehicleDocumentationAttachment.attachedFile?.attachment,
      mimeType: vehicleDocumentationAttachment.attachedFile?.mimeType,
      fileName: vehicleDocumentationAttachment.attachedFile?.fileName
    };

    const vehicles = [...confirmedVehicles, incomingVehicle];

    setConfirmedVehicles(vehicles);

    // Clear everything
    setIsInfoConfirmed(false);
    setActiveVehicle(null);
    setTagNumber('');
    setPageStep(RegisterBlankTagSteps.NoActiveVehicle);
    vehicleDocumentationAttachment.handleDeleteAttachment(); // Reset upload button
  };

  const removeVehicle = (vehicle: IVehicleWithObeAndAgreement) => {
    setIsInfoConfirmed(false);

    const rest = confirmedVehicles.filter(
      (confirmed) => confirmed.registrationNumber !== vehicle.registrationNumber
    );

    setConfirmedVehicles(rest);

    if (!rest.length) {
      setPageStep(RegisterBlankTagSteps.ChooseVehicleAndTag);
    }
  };

  const editVehicle = (
    vehicle: IVehicleWithObeAndAgreement,
    editable: boolean
  ) => {
    setIsInfoConfirmed(false);

    const v = confirmedVehicles.find(
      (x) => x.registrationNumber === vehicle.registrationNumber
    );

    if (v) v.isEditable = editable;
    setConfirmedVehicles([...confirmedVehicles]);
  };

  const updateAgreement = (
    vehicle: IVehicleWithObeAndAgreement,
    updatedAgreement: Agreement | string
  ) => {
    setIsInfoConfirmed(false);

    const v = confirmedVehicles.find(
      (x) => x.registrationNumber === vehicle.registrationNumber
    );

    if (v) {
      v.agreement = updatedAgreement;
      v.isEditable = false;
    }

    setConfirmedVehicles([...confirmedVehicles]);
  };

  const handleRegisterNewClick = () => {
    setIsInfoConfirmed(false);
    setSearchVehicle(null);
    setActiveVehicle(null);
    setAgreement('');
    setTagNumber('');
    setPageStep(RegisterBlankTagSteps.ChooseVehicleAndTag);
  };

  useEffect(() => {
    if (
      confirmedVehicles.length &&
      isInfoConfirmed &&
      pageStep === RegisterBlankTagSteps.NoActiveVehicle
    ) {
      setIsConfirmButtonAvailable(true);
    } else {
      setIsConfirmButtonAvailable(false);
    }
  }, [confirmedVehicles.length, isInfoConfirmed, pageStep]);

  // Sets forwardButton deactivation
  useEffect(() => {
    if (
      pageStep === RegisterBlankTagSteps.ChooseVehicleAndTag &&
      searchVehicle &&
      tagNumber
    ) {
      return setIsForwardDeactivated(false);
    }

    if (pageStep === RegisterBlankTagSteps.ChooseAgreement && searchVehicle)
      setActiveVehicle(searchVehicle);

    if (pageStep === RegisterBlankTagSteps.ChooseAgreement && agreement)
      return setIsForwardDeactivated(false);

    return setIsForwardDeactivated(true);
  }, [activeVehicle, agreement, pageStep, searchVehicle, tagNumber]);

  return {
    agreement,
    tagNumber,
    activeVehicle,
    confirmedVehicles,
    isForwardDeactivated,
    pageStep,
    isInfoConfirmed,
    isConfirmButtonAvailable,
    handleClickForward,
    setPageStep,
    setTagNumber,
    setAgreement,
    removeVehicle,
    editVehicle,
    updateAgreement,
    handleRegisterNewClick,
    setIsInfoConfirmed
  };
};

export default createContainer(useRegisterBlankTag);
