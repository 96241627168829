import React from 'react';
import { Router, Switch } from 'react-router-dom';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { useMSAL } from 'auth/msalProvider';
import { initTabListener } from '@fremtind/jkl-core';

// State Providers
import Compose from 'utils/compose/compose';
import CurrentCustomer from 'store/customer/useCurrentCustomer';
import InvoiceType from 'store/invoices/useInvoiceType';
import CustomAddress from 'store/address/useAddress';
import SelectedAgreement from 'store/agreements/useSelectedAgreement';
import AgreementResults from 'store/agreements/useAgreementResults';
import UpdateCustomer from 'store/customer/useUpdateCustomer';
import Privileges from 'store/privileges/usePrivileges';
import ObeFee from 'store/obe/useGetMonthlyObeFee';

import initAPI from 'api/initAPI';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import GenericErrorPage from 'modules/ErrorPage/GenericErrorPage';
import { reactPlugin } from 'utils/appInsights/appInsights';
import routes from './routes';
import SwitchRoute from './SwitchRoute';
import history from '../utils/history/history';

export default function Routes() {
  const { loading } = useMSAL();
    // Blocks render of state providers before authentication is complete
    if (loading) {
      return <LoadingPage />;
    }  

  // Init axios instance that handles calls with token
  initAPI();

  initTabListener();



  const Providers = [
    UpdateCustomer.Provider,
    CurrentCustomer.Provider,
    AgreementResults.Provider,
    InvoiceType.Provider,
    CustomAddress.Provider,
    SelectedAgreement.Provider,
    Privileges.Provider,
    ObeFee.Provider
  ];

  return (
    <Compose providers={Providers}>
      <Router history={history}>
        <AppInsightsErrorBoundary
          onError={() => <GenericErrorPage />}
          appInsights={reactPlugin}
        >
          <Switch>
            {routes.map((props) => (
              <SwitchRoute key={props.path.toString()} {...props} />
            ))}
          </Switch>
        </AppInsightsErrorBoundary>
      </Router>
    </Compose>
  );
}
