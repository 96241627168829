import { createContainer } from 'unstated-next';
import { useState } from 'react';
import { Agreement } from 'api';
import agreementOptions from 'constants/agreementOptions';

function useSelectedAgreement() {
  const [agreement, setAgreement] = useState<Agreement | string>('');
  const [currentAgreement, setCurrentAgreement] = useState<Agreement | string>(
    ''
  );

  const [agreementOption, setAgreementOption] = useState<string>(
    agreementOptions.EXISTING
  );
  const [agreementEditMode, setAgreementEditMode] = useState<boolean>(true);

  const agreementIsString = typeof agreement === 'string';

  const restartAgreementProcess = () => {
    setAgreementEditMode(true);
    setAgreementOption(agreementOptions.EXISTING);
    setAgreement('');
  };

  const resetAgreement = () => {
    restartAgreementProcess();
    setCurrentAgreement('');
  };

  return {
    agreement,
    setAgreement,
    agreementIsString,
    agreementOption,
    setAgreementOption,
    agreementEditMode,
    setAgreementEditMode,
    resetAgreement,
    restartAgreementProcess,
    currentAgreement,
    setCurrentAgreement
  };
}

export default createContainer(useSelectedAgreement);
