import {
  AuthorizationUrlRequest,
  Configuration,
  LogLevel
} from '@azure/msal-browser';
import { authentication } from '../../env-config.json';

export const FORGOT_PASSWORD_CODE = 'AADB2C90118';

export const PROFILE_REDIRECT: AuthorizationUrlRequest = {
  scopes: authentication.scopes
};

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: authentication.clientId,
    authority: `https://${authentication.B2CHostname}/${authentication.tenant}/${authentication.policyName}`,
    redirectUri: `${window.location.origin}/authcallback`,
    knownAuthorities: [authentication.B2CHostname]
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      }
    }
  }
};

export const getMsalForgotPasswordConfig = () => {
  const forgottenPassword: Configuration = MSAL_CONFIG;
  if (forgottenPassword.auth) {
    forgottenPassword.auth.authority = `https://${authentication.B2CHostname}/tfp/${authentication.tenant}/${authentication.policyForgotPassword}`;
  }

  return forgottenPassword;
};

export default MSAL_CONFIG;
