import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './TextStyles';
import IText from './IText';

export default function SmallHeader({ children, align, className }: IText) {
  const { header } = useStyles();

  return (
    <Typography align={align} variant="h5" className={`${header} ${className}`}>
      {children}
    </Typography>
  );
}

SmallHeader.defaultProps = {
  align: 'left',
  to: ''
};
