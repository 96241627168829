import React from 'react';
import { SnackbarProvider } from 'notistack';
import useStyles from './SnackProviderStyles';

interface ISnackProvider {
  children: JSX.Element;
}

export default function SnackProvider({ children }: ISnackProvider) {
  const { variantError } = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{ variantError }}
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
