const ELECTRONIC_WASTE = 'electronicWaste';
const AUTOSYNC_LOGO = 'autosyncLogo';
const AUTOSYNC_LOGO_SYMBOL = 'autosyncLogoSymbol';
const NO_VEHICLE = 'noVehicle';
const CAMERA_PASSING_ICON = 'cameraPassingIcon';
const OBE_PASSING_ICON = 'obePassingIcon';

export {
  ELECTRONIC_WASTE,
  AUTOSYNC_LOGO,
  AUTOSYNC_LOGO_SYMBOL,
  NO_VEHICLE,
  CAMERA_PASSING_ICON,
  OBE_PASSING_ICON
};
