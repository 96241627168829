import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import useStyles from './ButtonStyles';

interface IStepperButton {
  isBackDeactivated: boolean;
  isForwardDeactivated: boolean;
  setPageStep: (num: number) => void;
  onClickForward?: ((prop?: unknown) => void) | null;
  onClickBackward?: ((prop?: unknown) => void) | null;
  pageStep: number;
  alternativeForwardText?: string | JSX.Element;
  alternativeBackText?: string;
  loading?: boolean;
}

export default function StepperButton({
  isBackDeactivated,
  isForwardDeactivated,
  pageStep,
  setPageStep,
  onClickForward,
  onClickBackward,
  alternativeForwardText,
  alternativeBackText,
  loading
}: IStepperButton) {
  const { stepperRoot, spacingRight, wrapper } = useStyles();
  const { t } = useTranslation();

  const increment = () => {
    if (!isForwardDeactivated) {
      const incrementedPageStep = pageStep + 1;
      setPageStep(incrementedPageStep);
    }
  };

  const decrement = () => {
    if (!isBackDeactivated) {
      const decrementPageStep = pageStep - 1;
      setPageStep(decrementPageStep);
    }
  };

  return (
    <Box className={stepperRoot}>
      <Button
        className={spacingRight}
        disabled={isBackDeactivated}
        onClick={onClickBackward || decrement}
        aria-label="go back"
      >
        {alternativeBackText || t('buttons.go_back')}
      </Button>
      <div className={wrapper}>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading || isForwardDeactivated}
          onClick={onClickForward || increment}
          aria-label="go forward"
        >
          {alternativeForwardText || t('buttons.go_forward')}
        </Button>
        {loading && <Spinner isButtonProgress isWhiteSpinner />}
      </div>
    </Box>
  );
}

StepperButton.defaultProps = {
  alternativeForwardText: '',
  alternativeBackText: '',
  onClickForward: null,
  onClickBackward: null,
  loading: false
};
