import { ObeService } from "api";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

function useGetMonthlyObeFee() {
    const [monthlyFee, setMonthlyFee] = useState<number>(-1);
    const [isFetched, setIsFetched] = useState<boolean>();

    const getMonthlyFee = () => {
        if (monthlyFee == -1)
            fetchMonthlyFee();
        return monthlyFee;
    };

    const fetchMonthlyFee = async () => {
        try {
            setIsFetched(true);
            const res = await ObeService.getMonthlyObeFee();
            setMonthlyFee(res);
            console.log('fetched obe fee')
        } catch (error) {
            console.error(error);
            setMonthlyFee(-1);
            setIsFetched(false);
        }
    };
  
    return {getMonthlyFee};
  }
export default createContainer(useGetMonthlyObeFee);
  