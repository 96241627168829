import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {
  WHITE,
  DARK_GRAY,
  GRAY,
  LIGHT_GRAY,
  SUCCESS_GREEN,
  AUTOSYNC_BLACK
} from 'constants/colors';

const breakpoints = createBreakpoints({});

export default createTheme({
  palette: {
    primary: {
      main: WHITE,
      contrastText: AUTOSYNC_BLACK
    },
    secondary: {
      main: DARK_GRAY,
      light: GRAY,
      contrastText: WHITE
    },
    background: {
      default: LIGHT_GRAY
    }
  },

  typography: {
    fontFamily: ['CabinetGrotesk-Regular', 'Arial'].join(','),
    h3: { 
      fontFamily: ['CabinetGrotesk-Bold', 'Arial'].join(',')
    },
    h4: {
      fontFamily: ['CabinetGrotesk-Bold', 'Arial'].join(',')
    },
    h5: {
      fontFamily: ['GeneralSans-Semibold', 'Arial'].join(',')
    },
    subtitle1: {
      fontFamily: ['GeneralSans-Semibold', 'Arial'].join(',')
    },
    h6: {
      fontFamily: ['GeneralSans-Regular', 'Arial'].join(',')
    },
    subtitle2: {
      fontFamily: ['GeneralSans-Regular', 'Arial'].join(','),
      fontSize: '1.25rem'
    }
  },

  spacing: 8,

  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: 'bold'
      }
    },

    MuiFormLabel: {
      root: {
        '&$filled': {
          backgroundColor: LIGHT_GRAY
        },
        '&$shrink': {
          backgroundColor: LIGHT_GRAY
        }
      }
    },

    MuiAccordion: {
      root: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0
        },
        '&:before': {
          display: 'none'
        },
        '&$expanded': {
          margin: 'auto'
        }
      },
      expanded: {}
    },

    MuiAccordionSummary: {
      root: {
        padding: 0,
        backgroundColor: 'transparent',
        borderBottom: '1px solid rgba(0, 0, 0)',
        minHeight: 56,
        '&$expanded': {
          minHeight: 56
        }
      },
      content: {
        '&$expanded': {
          margin: '12px 0'
        }
      },
      expanded: {},
      expandIcon: {
        padding: 0
      }
    },

    MuiAccordionDetails: {
      root: {
        margin: '8px 0 0',
        padding: '8px 0 32px'
      }
    },

    MuiDrawer: {
      paper: {
        backgroundColor: WHITE,
        color: AUTOSYNC_BLACK
      }
    },

    MuiDialogContent: {
      root: {
        [breakpoints.down('xs')]: {
          width: '100%'
        }
      }
    },

    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: SUCCESS_GREEN
        }
      }
    },

    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: SUCCESS_GREEN
        }
      }
    },

    MuiButton: {
      contained: {
        backgroundColor: AUTOSYNC_BLACK,
        '&:hover': {
          '@media (hover:none)': {
            background: AUTOSYNC_BLACK
          }
        }
      },
      root: {
        textTransform: 'capitalize'
      }
    }
  }
});
