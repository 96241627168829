import { ArrowUpRight } from '@fremtind/jkl-icons-react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { AGREEMENTS, HOME, INVOICES, PASSINGS } from 'constants/routes';
import { EXTERNAL_HELP_PAGE } from 'constants/urls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useStyles from '../NavigationStyles';

export default function NavigationDrawerList() {
  const { list, listItem } = useStyles();
  const { t } = useTranslation();

  return (
    <List className={list}>
      {/* Home */}
      <ListItem
        className={listItem}
        button
        key="Home"
        component={NavLink}
        exact
        to={HOME}
        activeClassName="navlink-active"
      >
        <ListItemText primary={t('titles.home')} />
      </ListItem>

      {/* Agreement */}
      <ListItem
        className={listItem}
        button
        key="Agreement"
        component={NavLink}
        exact
        to={AGREEMENTS}
        activeClassName="navlink-active"
      >
        <ListItemText primary={t('titles.agreements')} />
      </ListItem>

      {/* Passings */}
      <ListItem
        className={listItem}
        button
        key="Passings"
        component={NavLink}
        to={PASSINGS}
        activeClassName="navlink-active"
      >
        <ListItemText primary={t('titles.passings')} />
      </ListItem>

      {/* Invoices */}
      <ListItem
        className={listItem}
        button
        key="Invoices"
        component={NavLink}
        to={INVOICES}
        activeClassName="navlink-active"
      >
        <ListItemText primary={t('titles.invoices')} />
      </ListItem>

      {/* Help */}
      <ListItem
        className={listItem}
        button
        key="Help"
        component={NavLink}
        to={{ pathname: EXTERNAL_HELP_PAGE }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemText primary={t('titles.help')} />
        <ArrowUpRight variant="inherit" />
      </ListItem>
    </List>
  );
}
