import React, { ReactNode } from 'react';
import { Icon } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import IText from 'components/Text/IText';
import { useHistory } from 'react-router-dom';
import useStyles from './ButtonStyles';
import SecondaryButton from './SecondaryButton';

interface IBackButton extends IText {
  children: ReactNode;
}

export default function BackButton({ children }: IBackButton) {
  const { backButtonWrapper, backButtonTitle } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={backButtonWrapper}>
      <SecondaryButton
        ariaLabel={t('buttons.back_button_arial_label')}
        onClick={() => history.goBack()}
        size="small"
      >
        <Icon>
          <NavigateBefore />
        </Icon>
      </SecondaryButton>
      <div className={backButtonTitle}>{children}</div>
    </div>
  );
}
