import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import { AddCircleOutlined } from '@material-ui/icons';
import { BoldSubtitle } from 'components/Text';
import useStyles from './ButtonStyles';

interface IAddButton {
  children: string;
  to: string;
}

export default function AddButton({ to, children }: IAddButton) {
  const { link, icon } = useStyles();

  return (
    <Link to={to} className={link}>
      <Icon className={icon}>
        <AddCircleOutlined />
      </Icon>
      <BoldSubtitle>{children}</BoldSubtitle>
    </Link>
  );
}
