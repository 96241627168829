import IIcon from 'common/interfaces/IIcon';
import {
  CAMERA_PASSING_ICON,
  ELECTRONIC_WASTE,
  AUTOSYNC_LOGO,
  NO_VEHICLE,
  OBE_PASSING_ICON
} from 'constants/icons';
import React from 'react';
import AutosyncLogo from './AutosyncLogo';
import CameraPassingIcon from './CameraPassingIcon';
import ElectronicWasteInfoBackground from './ElectronicWasteInfoBackground';
import NoVehicle from './NoVehicle';
import ObePassingIcon from './ObePassingIcon';

interface IIconProps extends IIcon {
  name: string;
}

const Icon = (props: IIconProps) => {
  switch (props.name) {
    case CAMERA_PASSING_ICON:
      return <CameraPassingIcon {...props} />
    case OBE_PASSING_ICON:
      return <ObePassingIcon {...props} />
    case ELECTRONIC_WASTE:
      return <ElectronicWasteInfoBackground {...props} />;
    case AUTOSYNC_LOGO:
      return <AutosyncLogo {...props} />;
    case NO_VEHICLE:
      return <NoVehicle {...props} />;
    default:
      return <div />;
  }
};

export default Icon;
