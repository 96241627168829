import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AUTOSYNC_BLACK, LIGHT_GRAY } from 'constants/colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2)
    },
    link: {
      color: AUTOSYNC_BLACK,
      fontFamily: 'inherit',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 400,
      padding: 0,
      backgroundColor: LIGHT_GRAY,
      border: 'none',
      textAlign: 'left'
    },
    content: {
      padding: theme.spacing(0, 3)
    },
    title: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    }
  })
);
