import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore next-line
import videoSrc from 'url:~/assets/AnimatedLogo/AutoSync-Logoanimasjon-Positiv.mp4';
import useStyles from './AutoSyncLogoAnimatedStyles';

const AutoSyncLogoAnimated: React.FC = () => {
  
  const { video } = useStyles();
  return (
    <video autoPlay muted className={video}>
      <source src={videoSrc} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default AutoSyncLogoAnimated;
