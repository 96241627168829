import '@fremtind/jkl-core/core.min.css';
import { Button } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useStyles from './ButtonStyles';

interface INavLinkButton {
  path: string;
  className: string;
  label: string;
  ariaLabel?: string;
}

export default function NavLinkButton({
  path,
  className,
  label,
  ariaLabel
}: INavLinkButton) {
  const { navLinkLabel } = useStyles();
  return (
    <Button
      classes={{ root: navLinkLabel }}
      component={NavLink}
      to={path}
      className={className}
      aria-labelledby={ariaLabel}
    >
      {label}
    </Button>
  );
}
